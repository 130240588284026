<div style="padding-left:5px !important;padding-right:5px;">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div>
    <br />
    <h1>Firmware .log files</h1>
  </div>
  <div class="mat-form-field--inline">
    <mat-form-field>
      <input matInput class="form-field" [formControl]="fileNameFilter" #FileName name="FileName" placeholder="File Name Filter">
    </mat-form-field>
    <p style="text-align:end">
      <button type="button" mat-stroked-button color="primary" (click)="exportAsExcel()">Export as Excel</button>
    </p>
  </div>

  <div #TABLE>
    <table mat-table [dataSource]="MyDataSource" matSort matSortStart="desc" class="mat-elevation-z8" style="width:100%;">
      <!-- ID Column -->
      <ng-container matColumnDef="filename">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="filename"> File Name </th>
        <td mat-cell *matCellDef="let row"> {{row.filename}} </td>
      </ng-container>

      <!-- Lap Column -->
      <ng-container matColumnDef="fileurl">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="fileurl"> Download </th>
        <td mat-cell *matCellDef="let row">
          {{row.fileUrl}} <button><a href="{{row.fileurl}}">download</a></button>
          <button ngxClipboard cbContent="{{row.fileurl}}">Copy</button>
          <button (click)="getRapDeviceLogFileData(row.fileurl)">Preview</button>
        </td>
      </ng-container>

      <ng-container matColumnDef="uploadtime">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="uploadtime"> Upload Date Time </th>
        <td mat-cell *matCellDef="let row"> {{row.uploadtime}} </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
  </div>
  <mat-paginator class="paginator--centered" [pageSizeOptions]="[10, 30, 50,100]" showFirstLastButtons></mat-paginator>

  <div style="height:400px;overflow:scroll;"><pre>{{filePreviewData}}</pre></div>
</div>
