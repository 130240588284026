import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../service/data-service.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import { FormControl } from '@angular/forms'
import { NgxSpinnerService } from "ngx-spinner";
import { filterfirmwarefilemodel } from '../model/firmwarefilemodel';

@Component({
  selector: 'app-filterfirmwarefileslist',
  templateUrl: './filterfirmwarefileslist.component.html',
  styleUrls: ['./filterfirmwarefileslist.component.css']
})
export class FilterFirmwarefileslistComponent implements OnInit {

  displayedColumns = ['test_id', 'mfg_timestamp', 'jlink_serial', 'uid', 'upc', 'test_tatus', 'rssi', 'overall_current', 'overall_status', 'firmware_filename', 'created_by', 'createdtime_utc'];
  MyDataSource: any;
  isLoading = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  test_idFilter =new FormControl();
  mfg_timestampFilter = new FormControl();
  jlink_serialFilter = new FormControl();
  uidFilter = new FormControl();
  upcFilter = new FormControl();
  test_tatusFilter = new FormControl();
  rssiFilter = new FormControl();
  overall_currentFilter = new FormControl();
  overall_statusFilter = new FormControl();
  firmware_filenameFilter = new FormControl();
  created_byFilter = new FormControl();
  createdtime_utcFilter = new FormControl();

  filteredValues = { test_id: '', mfg_timestamp: '', jlink_serial: '', uid: '' , upc: '', test_tatus: '', rssi: '', overall_current: '', overall_status: '', firmware_filename: '', created_by: '', createdtime_utc: ''};

  constructor(public dataService: DataService, private SpinnerService: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.RenderDataTable();

    this.test_idFilter.valueChanges.subscribe((test_idFilterValue) => {
      console.log("test_id:" + test_idFilterValue)
      this.filteredValues['test_id'] = test_idFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.mfg_timestampFilter.valueChanges.subscribe((mfg_timestampFilterValue) => {
      this.filteredValues['mfg_timestamp'] = mfg_timestampFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.jlink_serialFilter.valueChanges.subscribe((jlink_serialFilterValue) => {
      this.filteredValues['jlink_serial'] = jlink_serialFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.uidFilter.valueChanges.subscribe((uidFilterValue) => {
      this.filteredValues['uid'] = uidFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.upcFilter.valueChanges.subscribe((upcFilterValue) => {
      this.filteredValues['upc'] = upcFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.test_tatusFilter.valueChanges.subscribe((test_tatusFilterValue) => {
      this.filteredValues['test_tatus'] = test_tatusFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.rssiFilter.valueChanges.subscribe((rssiFilterValue) => {
      this.filteredValues['rssi'] = rssiFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.overall_currentFilter.valueChanges.subscribe((overall_currentFilterValue) => {
      this.filteredValues['overall_current'] = overall_currentFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.overall_statusFilter.valueChanges.subscribe((overall_statusFilterValue) => {
      this.filteredValues['overall_status'] = overall_statusFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.firmware_filenameFilter.valueChanges.subscribe((firmware_filenameFilterValue) => {
      this.filteredValues['firmware_filename'] = firmware_filenameFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.created_byFilter.valueChanges.subscribe((created_byFilterValue) => {
      this.filteredValues['created_by'] = created_byFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.createdtime_utcFilter.valueChanges.subscribe((createdtime_utcFilterValue) => {
      this.filteredValues['createdtime_utc'] = createdtime_utcFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

  }

  ngAfterViewInit() {
    //this.MyDataSource.paginator = this.paginator;
  }


  RenderDataTable() {
    this.isLoading = true;
    this.SpinnerService.show();
    this.dataService.getFilterFirmwareFilesData()
      .subscribe(
        res => {
          this.MyDataSource = new MatTableDataSource();
          this.isLoading = false;
          this.SpinnerService.hide();
          this.MyDataSource.data = res;
          this.MyDataSource.paginator = this.paginator;
          this.MyDataSource.sort = this.sort;
        },
        error => {
          this.isLoading = false;
          this.SpinnerService.hide();
          alert('There was an error while retrieving data !!!');
          console.log('There was an error while retrieving data !!! ' + error);
        }, () => {
          this.MyDataSource.filterPredicate = this.customFilterPredicate();
          this.MyDataSource.sort = this.sort;
          this.isLoading = false;
          this.SpinnerService.hide();
        });
  }

  customFilterPredicate() {
    const myFilterPredicate = function (data: filterfirmwarefilemodel, filter: string): boolean {
      let searchString = JSON.parse(filter);
      let test_idFound = data.test_id.toString().trim().toLowerCase().indexOf(searchString.test_id.trim().toLowerCase()) !== -1
      let mfg_timestampFound = data.mfg_timestamp.toString().trim().toLowerCase().indexOf(searchString.mfg_timestamp.trim().toLowerCase()) !== -1
      let jlink_serialFound = data.jlink_serial != null && data.jlink_serial.toString().trim().indexOf(searchString.jlink_serial.trim()) !== -1
      //let uidFound = data.uid != null && data.uid.toString().trim().indexOf(searchString.uid.trim()) !== -1
      let uidFound = data.uid.toString().trim().toLowerCase().indexOf(searchString.uid.trim().toLowerCase()) !== -1
      let upcFound = data.upc.toString().trim().toLowerCase().indexOf(searchString.upc.trim().toLowerCase()) !== -1
      let test_tatusFound = data.test_tatus.toString().trim().toLowerCase().indexOf(searchString.test_tatus.trim().toLowerCase()) !== -1
      let rssiFound = data.rssi.toString().trim().toLowerCase().indexOf(searchString.rssi.trim().toLowerCase()) !== -1
      let overall_currentFound = data.overall_current.toString().trim().toLowerCase().indexOf(searchString.overall_current.trim().toLowerCase()) !== -1
      let overall_statusFound = data.overall_status.toString().trim().toLowerCase().indexOf(searchString.overall_status.trim().toLowerCase()) !== -1
      let firmware_filenameFound = data.firmware_filename.toString().trim().toLowerCase().indexOf(searchString.firmware_filename.trim().toLowerCase()) !== -1
      let created_byFound = data.created_by.toString().trim().toLowerCase().indexOf(searchString.created_by.trim().toLowerCase()) !== -1
      let createdtime_utcFound = data.createdtime_utc.toString().trim().toLowerCase().indexOf(searchString.createdtime_utc.trim().toLowerCase()) !== -1

      if (searchString.topFilter) {
        return test_idFound || mfg_timestampFound || jlink_serialFound || uidFound || upcFound || test_tatusFound || rssiFound || overall_currentFound || overall_statusFound || firmware_filenameFound || created_byFound || createdtime_utcFound
      } else {
        return test_idFound && mfg_timestampFound && jlink_serialFound && uidFound && upcFound && test_tatusFound && rssiFound && overall_currentFound && overall_statusFound && firmware_filenameFound && created_byFound && createdtime_utcFound
      }
    }
    return myFilterPredicate;
  }

  public exportAsExcel(): void {
    const workSheet = XLSX.utils.json_to_sheet(this.MyDataSource.filteredData, { header: this.displayedColumns });
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'FilterManufacturingTestsData');
    XLSX.writeFile(workBook, 'FilterManufacturingTestsData.xlsx');
  }

}

