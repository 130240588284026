import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { DataService } from '../../service/data-service.service';

export interface Role {
  value: string;
  display: string;
}

@Component({
  selector: 'app-approvejobuser',
  templateUrl: './approvejobuser.component.html',
  styleUrls: ['./approvejobuser.component.css']
})
export class ApprovejobuserComponent implements OnInit {

  username: string;
  role: string
  isLoading = false;

  selectedValue: string;
 roles: Role[] = [
    { value: 'filtertestupload', display: 'filtertestupload' }
  ];

  constructor(
    public dialogRef: MatDialogRef<ApprovejobuserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any, private SpinnerService: NgxSpinnerService, private dataService: DataService) {
    this.username = data.username;
    this.role = data.role;
  }

  ngOnInit(): void {
  }

  onNoClick(): void {
    this.dialogRef.close({
      username: this.username
    });
  }

  onApprove(): void {
    
    this.isLoading = true;
    this.SpinnerService.show();

    // In a real-world app you'd have a http request / service call here like
    this.dataService.ApproveJobRole(this.username, this.selectedValue)
      .subscribe(
        res => {
          this.isLoading = false;
          this.SpinnerService.hide();
          alert('Approved role successfully.');
        },
        error => {
          this.isLoading = false;
          this.SpinnerService.hide();
          alert('Failed to approve role !!!');
          console.log('Failed to approve role !!!' + error);
        }, () => {
          this.isLoading = false;
          this.SpinnerService.hide();
      });

    this.dialogRef.close({
      
    });
  }

}
