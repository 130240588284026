<div style="padding-left:5px !important;padding-right:5px;">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div>
    <br />
    <h1>Firmware .csv file data</h1>
  </div>
  <div class="mat-form-field--inline">
    <mat-form-field>
      <input type="date" matInput class="form-field" [formControl]="fcDateFrom" #DateFrom name="nDateFrom" placeholder="Date From">
    </mat-form-field>
    <mat-form-field>
      <input type="date" matInput class="form-field" [formControl]="fcDateTo" #DateTo name="nDateTo" placeholder="Date To">
    </mat-form-field>
      <button type="button" mat-stroked-button (click)="GetFiles()" color="primary" >Get files</button>
      <mat-form-field>
        <mat-label>Select file name</mat-label>
        <mat-select multiple name="selected_FileName" [(ngModel)]="selected_FileName">
          <mat-option *ngFor="let item of fileNamesList" [value]="item.fileName" title="{{item.fileDataCount}} files">{{item.fileName}}</mat-option>
        </mat-select>
      </mat-form-field>
        <p style="display:inline !important;">
          <button type="button" mat-stroked-button color="primary" (click)="selectedFileNameChangeAction()">Search</button>
        </p>
  </div>
  <!-- <div class="mat-form-field--inline">
    
  </div> -->

    <div class="mat-form-field--inline">
      <mat-form-field>
        <input matInput class="form-field" [formControl]="macAddressFilter" #Mac_Address name="Mac_Address" placeholder="Mac Address Filter">
      </mat-form-field>
      <p style="text-align:end">
        <button type="button" mat-stroked-button color="primary" (click)="exportAsExcel()">Export as Excel</button>
      </p>
    </div>

    <!--<div class="mat-form-field--inline">
      <mat-form-field>
        <input matInput class="form-field" [formControl]="deviceKeyValues" placeholder="Multiple keys seperated by comma e.g tags.deviceId='02217e24a01e',tags.devicetype='smartrap',properties.reported.fwVersionCommsBoard='1.0.6.0'">
      </mat-form-field>

      <button type="button" mat-stroked-button color="primary" (click)="search()">Search</button>
      <button type="button" mat-stroked-button color="primary" (click)="searchFilter()">Get all scheduled</button>
    </div>-->

    <div #TABLE>
      <table mat-table [dataSource]="MyDataSource" matSort matSortStart="desc" class="mat-elevation-z8" style="width:100%;">
        <!-- ID Column -->
        <ng-container matColumnDef="mac_Address">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="mac_Address"> Mac_Address </th>
          <td mat-cell *matCellDef="let row"> {{row.mac_Address}} </td>
        </ng-container>

        <ng-container matColumnDef="sectigo_BatchID">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="sectigo_BatchID"> Sectigo BatchID </th>
          <td mat-cell *matCellDef="let row"> {{row.sectigo_BatchID}} </td>
        </ng-container>

        <!-- Lap Column -->
        <ng-container matColumnDef="certificate_FileName">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="certificate_FileName"> Certificate FileName </th>
          <td mat-cell *matCellDef="let row"> {{row.certificate_FileName}} </td>
        </ng-container>

        <ng-container matColumnDef="certificate_FlashTime">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="certificate_FlashTime"> Certificate Flash Time </th>
          <td mat-cell *matCellDef="let row"> {{row.certificate_FlashTime}} </td>
        </ng-container>

        <ng-container matColumnDef="status">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="status"> Status </th>
          <td mat-cell *matCellDef="let row"> {{row.status}} </td>
        </ng-container>

        <!-- Shortcut Column -->
        <ng-container matColumnDef="amazon_Certificate_ID">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="amazon_Certificate_ID"> Amazon Certificate ID </th>
          <td mat-cell *matCellDef="let row"> {{row.amazon_Certificate_ID}} </td>
        </ng-container>

        <ng-container matColumnDef="amazon_Certificate_Status">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="amazon_Certificate_Status"> Amazon Certificate Status </th>
          <td mat-cell *matCellDef="let row"> {{row.amazon_Certificate_Status}} </td>
        </ng-container>

        <ng-container matColumnDef="fileName">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="fileName"> File Name </th>
          <td mat-cell *matCellDef="let row"> {{row.fileName}} </td>
        </ng-container>

        <ng-container matColumnDef="uploadTime">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="uploadTime"> Upload date time </th>
          <td mat-cell *matCellDef="let row"> {{row.uploadTime}} </td>
        </ng-container>

        <ng-container matColumnDef="isDuplicate">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="isDuplicate"> Duplicate </th>
          <td mat-cell *matCellDef="let row">
            <span [style.color]="row.isDuplicate ? 'red' : null">
              {{row.isDuplicate==true?"Yes":"No"}}
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
    </div>
    <mat-paginator class="paginator--centered" [pageSizeOptions]="[10, 30, 50,100]" showFirstLastButtons></mat-paginator>
  </div>
