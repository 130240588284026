<div style="padding-left:5px !important;padding-right:5px;">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div>
    <br />
    <h1>Smart Air Purifiers</h1>
  </div>
  <div class="mat-form-field--inline">
    <mat-form-field>
      <input type="text" matInput class="form-field" [formControl]="fcSerialNumber" #SerialNumber name="nSerialNumber" placeholder="Serial Number">
    </mat-form-field>
    <!-- <mat-form-field>
    <input type="date" matInput class="form-field" [formControl]="fcDateTo" #DateTo name="nDateTo" placeholder="Date To">
  </mat-form-field> -->
    <!-- <button type="button" mat-stroked-button (click)="GetFiles()" color="primary" >Get files</button>
  <mat-form-field>
    <mat-label>Select file name</mat-label>
    <mat-select multiple name="selected_FileName" [(ngModel)]="selected_FileName">
      <mat-option *ngFor="let item of fileNamesList" [value]="item.fileName" title="{{item.fileDataCount}} files">{{item.fileName}}</mat-option>
    </mat-select>
  </mat-form-field> -->
    <p style="display:inline !important;">
      <button type="button" mat-stroked-button color="primary" (click)="getWarehouseSrapList()">Search</button>
    </p>
    <p style="display:inline !important;">
      <button type="button" mat-stroked-button color="primary" (click)="clearSearch()">Clear</button>
    </p>
    <p style="display:inline !important;">
      <button type="button" mat-stroked-button color="primary" (click)="getWarehouseSrapList()">Refresh Data</button>
    </p>
    <p style="display:inline !important;">
      <input type="checkbox" class="form-field" [formControl]="fcAutoRefresh" (change)="isAutoRefreshChecked()" title="Enable Auto Refresh" #AutoRefresh name="nAutoRefresh">Enable Auto Refresh (30 Sec)
    </p>
  </div>

    <!-- <div class="mat-form-field--inline">
      <mat-form-field>
        <input matInput class="form-field" [formControl]="serialNumberFilter" #serialNumber name="serialNumber" placeholder="Serial Number">
      </mat-form-field>
      <mat-form-field>
        <input matInput class="form-field" [formControl]="bluetoothIdentifierFilter" #bluetoothIdentifier name="bluetoothIdentifier" placeholder="Bluetooth Name">
      </mat-form-field>
      <mat-form-field>
        <input matInput class="form-field" [formControl]="macAddressFilter" #macAddress name="macAddress" placeholder="MAC Address">
      </mat-form-field>
      <mat-form-field>
        <input matInput class="form-field" [formControl]="firmwareUpdateStatusFilter" #firmwareUpdateStatus name="firmwareUpdateStatus" placeholder="Firmware Update Status">
      </mat-form-field>
      <p style="text-align:end">
        <button type="button" mat-stroked-button color="primary" (click)="exportAsExcel()">Export as Excel</button>
      </p>
    </div> -->

    
    <div class="example-container mat-elevation-z8">

      <mat-table [dataSource]="MyDataSource" matSort>

        <!-- ID Column -->
        <ng-container matColumnDef="serialNumber">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Serial Number </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Serial Number">
            <!-- {{row.serialNumber}}  g-->
            <a routerLink="/warehousesrapdetail" [queryParams]="{serialNumber: row.serialNumber}"><td> {{row.serialNumber}} <td></a>
          </mat-cell>
        </ng-container>

        <!-- Progress Column -->
        <ng-container matColumnDef="bluetoothIdentifier">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Bluetooth Name </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Bluetooth Name"> {{row.bluetoothIdentifier}} </mat-cell>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="macAddress">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Mac Address </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Mac Address"> {{row.macAddress}} </mat-cell>
        </ng-container>

        <!-- Color Column -->
        <ng-container matColumnDef="firmwareUpdateStatus">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Firmware Update Status </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Firmware Update">
            <span class="successhighlight" *ngIf="(row.firmwareUpdateStatus)">Success</span>
            <span class="pendinghighlight" *ngIf="(!row.firmwareUpdateStatus)">Pending</span>
          </mat-cell>
        </ng-container>

        <!-- Modified On Column -->
        <ng-container matColumnDef="updatedOn">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Modified On [UTC] </mat-header-cell>
          <mat-cell *matCellDef="let row" data-label="Modified On"> {{row.modifiedTS}} </mat-cell>
        </ng-container>

        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;">
        </mat-row>
      </mat-table>
    
      <!-- <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator> -->

      <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
      [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
      </mat-paginator>

    </div>
    
    
    <!-- <div #TABLE>
       

      <table mat-table [dataSource]="MyDataSourceDataResponse" matSort matSortStart="desc" class="mat-elevation-z8" style="width:100%;">

        <ng-container matColumnDef="serialNumber">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="serialNumber"> Serial Number </th>
          <td mat-cell *matCellDef="let row">  
            <a routerLink="/warehousesrapdetail" [queryParams]="{serialNumber: row.serialNumber}" ><td> {{row.serialNumber}} <td></a>
          </td>
        </ng-container>

        <ng-container matColumnDef="bluetoothIdentifier">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="bluetoothIdentifier"> Bluetooth Name </th>
          <td mat-cell *matCellDef="let row"> {{row.bluetoothIdentifier}} </td>
        </ng-container>

        <ng-container matColumnDef="macAddress">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="macAddress"> Mac Address </th>
          <td mat-cell *matCellDef="let row"> {{row.macAddress}} </td>
        </ng-container>

        <ng-container matColumnDef="firmwareUpdateStatus">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="firmwareUpdateStatus"> Firmware Update Status </th>
          <td mat-cell *matCellDef="let row"> {{row.firmwareUpdateStatus}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>

      <mat-paginator class="paginator--centered" [pageSizeOptions]="[10, 30, 50,100]" showFirstLastButtons></mat-paginator>
    </div> -->
    
  </div>
