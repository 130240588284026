import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DataService } from '../service/data-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApproveuserComponent } from '../modals/approveuser/approveuser.component';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Component({
  selector: 'app-userslist',
  templateUrl: './userslist.component.html',
  styleUrls: ['./userslist.component.css']
})
export class UserslistComponent implements OnInit {
  displayedColumns = ['username', 'firstname', 'lastname', 'role','action'];
  MyDataSource: any;
  isLoading = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  username: string;
  role: string;

  constructor(public dataService: DataService, private SpinnerService: NgxSpinnerService, public dialog: MatDialog, public router: Router) {
  }

  ngOnInit(): void {
    this.RenderDataTable();
  }

  ngAfterViewInit() {
    //this.MyDataSource.paginator = this.paginator;
  }


  RenderDataTable() {
    this.isLoading = true;
    this.SpinnerService.show();
    this.dataService.getUsers()
      .subscribe(
        res => {
          this.MyDataSource = new MatTableDataSource();
          this.isLoading = false;
          this.SpinnerService.hide();
          this.MyDataSource.data = res;
          this.MyDataSource.paginator = this.paginator;
          this.MyDataSource.sort = this.sort;
        },
        error => {
          this.isLoading = false;
          this.SpinnerService.hide();
          alert('There was an error while retrieving data !!!');
          console.log('There was an error while retrieving data !!!' + error);
        }, () => {
          this.MyDataSource.sort = this.sort;
          this.isLoading = false;
          this.SpinnerService.hide();
        });
  }

  ApproveUser(username,role) {
    const dialogRef = this.dialog.open(ApproveuserComponent, {
      width: '500px',
      data: { username: username, role: role }
    });

    dialogRef.afterClosed().subscribe(result => {
      this.username = result;
      this.delay(800).then(any => {
        this.RenderDataTable();
      });
    });
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms));
  }

}
