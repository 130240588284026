
<div class="container">
  <mat-toolbar color="primary" style="background-color:black">
    <div fxShow="true">
      <button mat-icon-button (click)="sidenav.toggle()">
        <mat-icon>menu</mat-icon>
        Menu
      </button>
    </div>
    <img src="../help/help/3m_image.png" style="width: 38px; height: 19px;margin-left: 20px;" />
    <a mat-button class="companyName">
      <span>Filtrete Factory Dashboard</span>
    </a>
    <span class="example-spacer"></span>
    <div fxShow="true" fxHide.lt-md="true" *ngIf="(isLoggedIn$ | async)">
      <button mat-button *ngFor="let user of currentuser">  
        {{user.lastname}} {{user.firstname}} ({{user.role}})
      </button>
  <!--
  <a mat-button routerLink="/firmwarehistory" *ngIf="(roleadmin$ | async)">Firmware history</a>
  <a mat-button routerLink="/firmwareloaderfilesupload" *ngIf="(roleadmin$ | async)">Firmware loader upload</a>
  <a mat-button routerLink="/firmwareloaderfiles" *ngIf="(roleadmin$ | async)">Firmware loader list</a>
  <a mat-button routerLink="/serialnumber" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async)">Serial number upload</a>
  <a mat-button routerLink="/serialnumberlist" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async) || (rolereader$ | async)">Serial number list</a>
  <a mat-button routerLink="/firmwarefileupload" *ngIf="(roleadmin$ | async)">Firmware file upload</a>
  <a mat-button routerLink="/firmwarefileslist" *ngIf="(roleadmin$ | async)">Firmware files</a>
  <a mat-button routerLink="/rapdevicelogfileupload" *ngIf="(roleadmin$ | async)">Rap log files upload</a>
  <a mat-button routerLink="/rapdevicelogfiles" *ngIf="(roleadmin$ | async)">Rap log files</a>
  <a mat-button routerLink="/userslist" *ngIf="(roleadmin$ | async)">Users</a>
  <a mat-button routerLink="/sectigofiles" *ngIf="(roleadmin$ | async)">Sectigo data</a>
      <a mat-button (click)="onLogout();">Logout</a>
  -->
 
      <button mat-button [matMenuTriggerFor]="main_menu_filter" *ngIf="(rolefilteradmin$ | async) || (rolefiltercontributor$ | async) || (rolefilterreader$ | async)" #main_menu_filterTrigger="matMenuTrigger">
        Filter Manufacturing <span style="font-size:10px;">&#x25BC;</span>
      </button>

      <mat-menu #main_menu_filter="matMenu">
        <ng-container>
          <button mat-menu-item routerLink="/filterdashboard" *ngIf="(rolefilteradmin$ | async)">Filter Dashboard</button>
          <button mat-menu-item routerLink="/filterfirmwarefileslist" *ngIf="(rolefilteradmin$ | async) || (rolefiltercontributor$ | async) || (rolefilterreader$ | async)">Filter Test list</button>
          <button mat-menu-item routerLink="/jobuserslist" *ngIf="(rolefilteradmin$ | async)">Whitelist Approval</button>
        </ng-container>
      </mat-menu>

      <button mat-button [matMenuTriggerFor]="main_menu_warehouse" *ngIf="(rolewarehouseadmin$ | async) || (rolewarehousecontributor$ | async) || (rolewarehousereader$ | async)" #main_menu_warehouseTrigger="matMenuTrigger">
        Warehouse SRAP <span style="font-size:10px;">&#x25BC;</span>
      </button>

      <mat-menu #main_menu_warehouse="matMenu">
        <ng-container>
          <button mat-menu-item routerLink="/warehousesraplist" *ngIf="(rolewarehouseadmin$ | async) || (rolewarehousecontributor$ | async) || (rolewarehousereader$ | async)">Warehouse SRAP List</button>
          <button mat-menu-item routerLink="/whitelistserialnumber" *ngIf="(rolewarehouseadmin$ | async)">Serial Number Whitelisting</button>
          <!-- <button mat-menu-item routerLink="/warehousesrapdetail" [queryParams]="{serialNumber: 'RAP_CER_TS01'}" *ngIf="(rolefilteradmin$ | async) || (rolefiltercontributor$ | async) || (rolefilterreader$ | async)">Warehouse SRAP Detail</button> -->
        </ng-container>
      </mat-menu>

      <button mat-button [matMenuTriggerFor]="main_menu" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async)" #levelOneTrigger="matMenuTrigger">
        Firmware <span style="font-size:10px;">&#x25BC;</span>
      </button>

      <mat-menu #main_menu="matMenu">
        <ng-container>
          <button mat-menu-item routerLink="/firmwarehistory" *ngIf="(roleadmin$ | async)">Firmware history</button>
          <button mat-menu-item routerLink="/firmwareloaderfilesupload" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async)">Firmware .csv file upload</button>
          <button mat-menu-item routerLink="/firmwareloaderfiles" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async)">Firmware .csv files list</button>
          <button mat-menu-item routerLink="/firmwarefileupload" *ngIf="(roleadmin$ | async)">Firmware bin file upload</button>
          <button mat-menu-item routerLink="/firmwarefileslist" *ngIf="(roleadmin$ | async)">Firmware bin files list</button>
        </ng-container>
      </mat-menu>

      <button mat-button [matMenuTriggerFor]="main_menu_sn" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async) || (rolereader$ | async)" #SnTrigger="matMenuTrigger">
        Serial Number <span style="font-size:10px;">&#x25BC;</span>
      </button>

      <mat-menu #main_menu_sn="matMenu">
        <ng-container>
          <button mat-menu-item routerLink="/serialnumber" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async)">Serial Number file upload</button>
          <button mat-menu-item routerLink="/serialnumberlist" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async) || (rolereader$ | async)">Serial Number files list</button>
        </ng-container>
      </mat-menu>
      <button mat-button [matMenuTriggerFor]="main_menu_raplog" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async)" #LogFileTrigger="matMenuTrigger">
        Firmware .log file <span style="font-size:10px;">&#x25BC;</span>
      </button>

      <mat-menu #main_menu_raplog="matMenu">
        <ng-container>
          <button mat-menu-item routerLink="/rapdevicelogfileupload" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async)">Firmware .log file upload</button>
          <button mat-menu-item routerLink="/rapdevicelogfiles" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async)">Firmware .log files list</button>
        </ng-container>
      </mat-menu>
      <a mat-button routerLink="/sectigofiles" *ngIf="(roleadmin$ | async)">Sectigo data</a>
      <a mat-button routerLink="/userslist" *ngIf="(roleadmin$ | async)">Users</a>
      <a mat-button (click)="onLogout();">Logout</a>
    </div>


  </mat-toolbar>
    <mat-sidenav-container fxFlexFill class="example-container">

      <mat-sidenav color="primary" #sidenav fxLayout="column" mode="over" opened="false">
        <div fxLayout="column" *ngIf="(isLoggedIn$ | async)">
          <a mat-button routerLink="/firmwarehistory" (click)="sidenav.toggle()" *ngIf="(roleadmin$ | async)">Firmware history</a>
          <a mat-button routerLink="/firmwareloaderfilesupload" (click)="sidenav.toggle()" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async)">Firmware .csv file upload</a>
          <a mat-button routerLink="/firmwareloaderfiles" (click)="sidenav.toggle()" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async)">Firmware .csv files list</a>
          <a mat-button routerLink="/firmwarefileupload" (click)="sidenav.toggle()" *ngIf="(roleadmin$ | async)">Firmware binary file upload</a>
          <a mat-button routerLink="/firmwarefileslist" (click)="sidenav.toggle()" *ngIf="(roleadmin$ | async)">Firmware binary files</a>
          <a mat-button routerLink="/filterdashboard" (click)="sidenav.toggle()" *ngIf="(rolefilteradmin$ | async)">Filter Dashboard</a>
          <a mat-button routerLink="/filterfirmwarefileslist" (click)="sidenav.toggle()" *ngIf="(rolefilteradmin$ | async) || (rolefiltercontributor$ | async) || (rolefilterreader$ | async)">Filter Test List</a>
          <a mat-button routerLink="/jobuserslist" (click)="sidenav.toggle()" *ngIf="(rolefilteradmin$ | async)">Whitelist Approval</a>

          <a mat-button routerLink="/warehousesraplist" (click)="sidenav.toggle()" *ngIf="(rolewarehouseadmin$ | async) || (rolewarehousecontributor$ | async) || (rolewarehousereader$ | async)">Warehouse SRAP List</a>
          <!-- <a mat-button routerLink="/warehousesrapdetail" (click)="sidenav.toggle()" *ngIf="(rolewarehouseadmin$ | async) || (rolewarehousecontributor$ | async) || (rolewarehousereader$ | async)">Warehouse SRAP Detail</a> -->
          <a mat-button routerLink="/whitelistserialnumber" (click)="sidenav.toggle()" *ngIf="(rolewarehouseadmin$ | async)">Serial Number Whitelisting</a>

          <a mat-button routerLink="/serialnumber" (click)="sidenav.toggle()" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async)">Serial number file upload</a>
          <a mat-button routerLink="/serialnumberlist" (click)="sidenav.toggle()" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async) || (rolereader$ | async)">Serial number files list</a>
          <a mat-button routerLink="/rapdevicelogfileupload" (click)="sidenav.toggle()" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async)">Firmware .log file upload</a>
          <a mat-button routerLink="/rapdevicelogfiles" (click)="sidenav.toggle()" *ngIf="(roleadmin$ | async) || (rolecontributor$ | async)">Firmware .log files</a>
          <a mat-button routerLink="/sectigofiles" (click)="sidenav.toggle()" *ngIf="(roleadmin$ | async)">Sectigo data</a>
          <a mat-button routerLink="/userslist" (click)="sidenav.toggle()" *ngIf="(roleadmin$ | async)">Users</a>
          <a mat-button (click)="onLogout();sidenav.toggle();" *ngIf="(isLoggedIn$ | async)">Logout</a>
        </div>
      </mat-sidenav>
      <mat-sidenav-content fxFlexFill>
        <div style="font-size:initial !important;text-align:left !important;"><router-outlet></router-outlet></div>
      </mat-sidenav-content>
    </mat-sidenav-container>
</div>
