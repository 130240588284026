<div class="alert alert-info">
</div>
<div style="margin-left:5%;margin-right:5%;">
  <form [formGroup]="loginForm" method="post">
    <fieldset style="border-radius:5px;">
      <!-- <legend>Smart Air Purifier</legend> -->
      <h1>Smart Air Purifier : {{serialNumberValue}}</h1>
      <span [class]="deviceStatus === 'Firmware Upgraded' ? 'greenhighlight' : 'redhighlight'">&nbsp; {{deviceStatus}} &nbsp;</span>
      <p style="display:inline !important;">
        <button type="button" mat-stroked-button color="primary" (click)="RenderDataTable()">Refresh Data</button>
      </p>
      <br />
      <br />
      <div class="form-group">

        <!-- <label #lblName id="lblName"> Serial Number </label> -->
        <label for="serialNumberText" class="control-label"> Serial Number : </label>
        <label for="serialNumberValue">{{serialNumberValue}}</label>
        <!-- <input id="serialnumber" type="text" formControlName="serialnumber">  -->
        
        <!-- <label for="serialnumber" class="control-label">Serial Number : </label>
        <label for="serialnumbervalue" formControlName="serialnumber" class="control-label">Serial Number Value</label> -->
        <!-- <input type="email" formControlName="username" placeholder="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
          <div *ngIf="f.username.errors.required">Username is required</div>
          <div *ngIf="f.username.errors.email">Invalid email id</div>
        </div> -->
      </div>
      <br />
      <div class="form-group">
        <label for="macAddressText" class="control-label">MAC Address : </label>
        <label for="macAddressValue" class="control-label">{{macAddressValue}}</label>
        <!-- <input type="password" formControlName="password" placeholder="password" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div> -->
      </div>
      <br />
      <div class="form-group">
        <label for="registrationId" class="control-label">Registration Id : </label>
        <label for="registrationId" class="control-label">{{registrationId}}</label>
        <!-- <input type="password" formControlName="password" placeholder="password" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div> -->
      </div>
      <br />
      <div class="form-group">
        <label for="bluetoothname" class="control-label">Bluetooth Name : </label>
        <label for="bluetoothNameValue" class="control-label">{{bluetoothNameValue}}</label>
        <!-- <input type="password" formControlName="password" placeholder="password" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div> -->
      </div>
      <br />
      <div class="form-group">
        <label for="isWareHouseDevice" class="control-label">WareHouse Device : </label>
        <label for="isWareHouseDevice" class="control-label">{{isWareHouseDevice}}</label>
        <!-- <input type="password" formControlName="password" placeholder="password" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div> -->
      </div>
      <br />
      <div class="form-group">
        <label for="firmwareVersion" class="control-label">Firmware Version : </label>
        <label for="firmwareVersion" class="control-label">{{firmwareVersion}}</label>
        <!-- <input type="password" formControlName="password" placeholder="password" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div> -->
      </div>
      <br />
      <div class="form-group">
        <label for="firmwareupdatestatus" class="control-label">Firmware Update Status : </label>
        <label for="firmwareUpdateStatusValue" class="control-label">{{firmwareUpdateStatusValue}}</label>
        <!-- <input type="password" formControlName="password" placeholder="password" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div> -->
      </div>
      <br />
      <div class="form-group">
        <label for="comments" class="control-label">Comments : </label>
        <label for="comments" class="control-label">{{comments}}</label>
        <!-- <input type="password" formControlName="password" placeholder="password" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div> -->
      </div>
      <br />
      <div class="form-group">
        <label for="createdTS" class="control-label">Created Time : </label>
        <label for="createdTS" class="control-label">{{createdTS}}</label>
        <!-- <input type="password" formControlName="password" placeholder="password" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div> -->
      </div>
      <br />
      <div class="form-group">
        <label for="modifiedTS" class="control-label">Modified Time : </label>
        <label for="modifiedTS" class="control-label">{{modifiedTS}}</label>
        <!-- <input type="password" formControlName="password" placeholder="password" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div> -->
      </div>

      <br />
      <div class="form-group">
        <div>
          Go to <a routerLink="/warehousesraplist" id="warehousesraplist" aria-label="Warehouse SRAP List">Warehouse SRAP List</a>
        </div>
        <div *ngIf="error" class="alert alert-danger">
          {{error}
        }</div>
      </div>
    </fieldset>
  </form>
  </div>
