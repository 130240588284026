import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { DataService } from '../service/data-service.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import { FormControl } from '@angular/forms'
import { NgxSpinnerService } from "ngx-spinner";
import { productinfomodel } from '../model/warehousesrapmodel';
import { first } from 'rxjs/operators';
import { SearchValues, SearchObject } from '../model/SearchObject';

@Component({
  selector: 'app-warehousesraplistcomponent',
  templateUrl: './warehousesraplist.component.html',
  styleUrls: ['./warehousesraplist.component.css']
})
export class WarehousesraplistComponent implements OnInit, AfterViewInit {

  //displayedColumns = ['deviceId', '_sTime', 'macAddress', 'sys_Sn', 'raP_Ver', 'comms_Ver', 'cert_CommonName', 'fileName','uploadTime','isDuplicate'];
  displayedColumns = ['serialNumber', 'bluetoothIdentifier', 'macAddress', 'firmwareUpdateStatus', 'updatedOn'];
  MyDataSource: any;
  MyDataSourceDataResponse; any;
  //selected_FileName: string;
  isLoading = false;
  searchObject: any;
  searchValues: any;
  totalRows = 0;
  pageSize = 5;
  currentPage = 0;
  pageSizeOptions: number[] = [5, 10, 25, 50, 100, 1000];
  interval: any = null;

  //searchObject = new SearchObject();
    
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  fileNamesList: any;
  // serialNumberFilter = new FormControl();
  // bluetoothIdentifierFilter = new FormControl();
  // macAddressFilter = new FormControl();
  // firmwareUpdateStatusFilter = new FormControl();

  // filteredValues = { serialNumber: '', bluetoothIdentifier: '', macAddress: '', firmwareUpdateStatus: '' };

  constructor(public dataService: DataService, private SpinnerService: NgxSpinnerService) {
  }

  fcSerialNumber = new FormControl();
  fcAutoRefresh = new FormControl();
  //fcDateTo = new FormControl();

  ngOnInit(): void {
    //this.RenderListOfSerialNumberFiles();

    this.fcAutoRefresh.setValue(false);
    this.RenderDataTable("");

   

  }

//  setInterval(() => {
//    //replaced function() by ()=>
//    this.myDate = new Date();
//    console.log(this.myDate);
//    // just testing if it is working
//}, 1000);

  ngAfterViewInit() {
    //this.MyDataSource.paginator = this.paginator;
  }

  isAutoRefreshChecked() {

    var autorefreshValue = this.fcAutoRefresh.value
    
    console.log(autorefreshValue);
    if (autorefreshValue) {
      this.interval = setInterval(() => {
        console.log(this.fcSerialNumber.value);
        this.RenderDataTable(this.fcSerialNumber.value);
      }, 30000);
    }
    else {
      console.log(this.interval);
      if (this.interval != null)
        clearInterval(this.interval);
    }



  }

  ValidateSerialNumber() {
    var msg = "";
    var serialnumber = "";
    serialnumber = this.fcSerialNumber.value;
  
    //var dtTo = "";
    //dtTo = this.fcDateTo.value;
  
    if (serialnumber == undefined || serialnumber == null || serialnumber.length <=0 ) {
      msg += "Serial Number";
    }
    
    // if (dtTo == undefined || dtTo == null || dtTo.length <=0) {
    //   if(msg.length > 0)
    //   msg += " and "
    //    msg += "To Date";
    // }

    if(msg.length > 0)
    {
      msg = "Enter " + msg;
    }
    return msg;
  }

  // GetFiles() {
  //   var msg = this.ValidateDate();
  //   if(msg.length > 0)
  //   {
  //     alert(msg);
  //   }
  //   else {
  //     this.RenderListOfSerialNumberFiles();
  //   }
  // }

  // RenderListOfSerialNumberFiles() {
  //   this.isLoading = true;
  //   this.SpinnerService.show();
  //   var dateFrom = this.fcDateFrom.value == null ? "" : this.fcDateFrom.value.trim();
  //   var dateTo = this.fcDateTo.value == null ? "" : this.fcDateTo.value.trim();
  //   this.dataService.getSerialNumberFilesList(dateFrom,dateTo)
  //     .subscribe(
  //       res => {
  //         this.isLoading = false;
  //         this.SpinnerService.hide();
  //         this.fileNamesList = res;
  //       },
  //       error => {
  //         this.isLoading = false;
  //         this.SpinnerService.hide();
  //         alert('There was an error while retrieving list of serial number files !!!');
  //         console.log('There was an error while retrieving data !!!' + error);
  //       }, () => {
  //         this.isLoading = false;
  //         this.SpinnerService.hide();
  //       });
  // }

  clearSearch() {

    this.fcSerialNumber.setValue(null);
    this.getWarehouseSrapList();
  }

  getWarehouseSrapList() {
    //var msg = this.ValidateSerialNumber();

   

      this.totalRows = 0;
    //this.pageSize = 2;
      this.currentPage = 0;
  
      var serialNumber = this.fcSerialNumber.value

    //if (serialNumber != undefined && serialNumber.length >= 0) {
        
      
        //replaced function() by ()=>
     this.RenderDataTable(serialNumber);
       
     
     

    // if (this.selected_FileName != undefined && msg.length <= 0) {
    //   this.RenderDataTable(this.selected_FileName);

      // this.serialNumberFilter.valueChanges.subscribe((serialNumberFilterValue) => {
      //   this.filteredValues['serialNumber'] = serialNumberFilterValue.trim();
      //   this.MyDataSourceDataResponse.filter = JSON.stringify(this.filteredValues);
      // });

      // this.bluetoothIdentifierFilter.valueChanges.subscribe((bluetoothIdentifierFilterValue) => {
      //   this.filteredValues['bluetoothIdentifier'] = bluetoothIdentifierFilterValue.trim();
      //   this.MyDataSourceDataResponse.filter = JSON.stringify(this.filteredValues);
      // });

      // this.macAddressFilter.valueChanges.subscribe((macAddressFilterValue) => {
      //   this.filteredValues['macAddress'] = macAddressFilterValue.trim();
      //   this.MyDataSourceDataResponse.filter = JSON.stringify(this.filteredValues);
      // });

      // this.firmwareUpdateStatusFilter.valueChanges.subscribe((firmwareUpdateStatusFilterValue) => {
      //   this.filteredValues['firmwareUpdateStatus'] = firmwareUpdateStatusFilterValue.trim();
      //   this.MyDataSourceDataResponse.filter = JSON.stringify(this.filteredValues);
      // });

    //}
    // else {
    //   msg += '\r\nEnter SerialNumber.' 
    //   alert(msg);
    // }
  }

  RenderDataTable(serialNumber) {
    this.isLoading = true;
    this.SpinnerService.show();
    //var dateFrom = this.fcDateFrom.value == null ? "" : t his.fcDateFrom.value.trim();
    //var dateTo = this.fcDateTo.value == null ? "" : this.fcDateTo.value.trim();
    this.searchObject = new SearchObject();

    this.searchValues = new SearchValues();
    this.searchValues.searchKey = "serialnumber";
    this.searchValues.searchValue = this.fcSerialNumber.value;

    this.searchObject.searchValues  = [];
    this.searchObject.searchValues.push(this.searchValues);

    this.searchObject.ordering = "firmwareUpdateStatus asc";
    this.searchObject.offset = this.currentPage + 1;
    this.searchObject.limit = this.pageSize;

    this.dataService.getWarehouseSRAPList(this.searchObject)
      .pipe(first())
      .subscribe(
        res => {
          this.MyDataSource = new MatTableDataSource();
          
          
          this.isLoading = false;
          this.SpinnerService.hide();
          console.log(res);
          this.MyDataSource.data = res;
          //this.MyDataSourceDataResponse = this.MyDataSource.data.response;
          //this.MyDataSourceDataResponse = this.MyDataSource.data.response.smartAirPurifiers;
          this.totalRows = this.MyDataSource.data.response.totalRows;
          this.paginator.length = this.MyDataSource.data.response.totalRows;
          this.MyDataSource.data = this.MyDataSource.data.response.smartAirPurifiers;
          /*
          <mat-paginator #paginator [length]="totalRows" [pageIndex]="currentPage" [pageSize]="pageSize"
          [pageSizeOptions]="pageSizeOptions" (page)="pageChanged($event)" aria-label="Select page">
          </mat-paginator>
          */
          //setTimeout(() => {
            this.currentPage = this.currentPage;
            this.paginator.pageIndex = this.currentPage;
            this.paginator.pageSize = this.pageSize;
            //-this.paginator.length = this.MyDataSource.data.length;
          //});

          //this.MyDataSourceDataResponse.paginator = this.paginator;
          //this.MyDataSource.paginator = this.paginator;
          //this.MyDataSourceDataResponse.sort = this.sort;
          this.MyDataSource.sort = this.sort;


        },
        error => {
          this.isLoading = false;
          this.SpinnerService.hide();
          alert('There was an error while retrieving data !!!');
          console.log('There was an error while retrieving data !!!' + error);
        }, () => {
          //moved to top
          // this.MyDataSourceDataResponse.filterPredicate = this.customFilterPredicate();
          //this.MyDataSourceDataResponse.sort = this.sort;
          this.MyDataSource.sort = this.sort;
          this.isLoading = false;
          this.SpinnerService.hide();

        });
  }

  pageChanged(event: PageEvent) {
    console.log({ event });
    this.pageSize = event.pageSize;
    this.currentPage = event.pageIndex;
    this.RenderDataTable(""); 
   }

  // customFilterPredicate() {
  //   const myFilterPredicate = function (data: productinfomodel, filter: string): boolean {
  //     let searchString = JSON.parse(filter);
  //     let serialNumberFound = data.serialNumber.toString().trim().toLowerCase().indexOf(searchString.serialNumber.trim().toLowerCase()) !== -1
  //     // let bluetoothIdentifierFound = data.bluetoothIdentifier != null && data.bluetoothIdentifier.toString().trim().indexOf(searchString.bluetoothIdentifier.trim()) !== -1
  //     // let macAddressFound = data.macAddress != null && data.macAddress.toString().trim().indexOf(searchString.macAddress.trim()) !== -1
  //     // let firmwareUpdateStatusFound = data.firmwareUpdateStatus != null && data.firmwareUpdateStatus.toString().trim().indexOf(searchString.firmwareUpdateStatus.trim()) !== -1

  //     if (searchString.topFilter) {
  //       return serialNumberFound //|| bluetoothIdentifierFound || macAddressFound || firmwareUpdateStatusFound
  //     } else {
  //       return serialNumberFound //&& bluetoothIdentifierFound && macAddressFound && firmwareUpdateStatusFound
  //     }
  //   }
  //   return myFilterPredicate;
  // }


  // customFilterPredicate() {
  //   const myFilterPredicate = function (data: productinfomodel, filter: string): boolean {
  //     let searchString = JSON.parse(filter);
  //       let serialNumberFound = data.serialNumber.toString().trim().toLowerCase().indexOf(searchString.serialNumber.trim().toLowerCase()) !== -1
  //       // let bluetoothIdentifierFound = data.bluetoothIdentifier != null && data.bluetoothIdentifier.toString().trim().indexOf(searchString.bluetoothIdentifier.trim()) !== -1
  //       // let macAddressFound = data.macAddress != null && data.macAddress.toString().trim().indexOf(searchString.macAddress.trim()) !== -1
  //       // let firmwareUpdateStatusFound = data.firmwareUpdateStatus != null && data.firmwareUpdateStatus.toString().trim().indexOf(searchString.firmwareUpdateStatus.trim()) !== -1

  //       if (searchString.topFilter) {
  //         return serialNumberFound //|| bluetoothIdentifierFound || macAddressFound || firmwareUpdateStatusFound
  //       } else {
  //         return serialNumberFound //&& bluetoothIdentifierFound && macAddressFound && firmwareUpdateStatusFound
  //       }
  //     }
  //     return myFilterPredicate;

  // }

  // public exportAsExcel(): void {
  //   const workSheet = XLSX.utils.json_to_sheet(this.MyDataSourceDataResponse.filteredData, { header: this.displayedColumns });
  //   const workBook: XLSX.WorkBook = XLSX.utils.book_new();
  //   XLSX.utils.book_append_sheet(workBook, workSheet, 'WarehouseSRAPListData');
  //   XLSX.writeFile(workBook, 'WarehouseSRAPListData.xlsx');
  // }

}
