<div class="alert alert-info">
</div>
<div style="margin-left:5%;margin-right:50%;">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()"  method="post">
    <fieldset style="border-radius:5px;">
      <legend>Whitelist</legend>
      <div class="form-group">
        <label for="username" class="control-label required">Username/Email</label>
        <input type="email" formControlName="username" class="form-control" placeholder="email" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
          <div *ngIf="f.username.errors.required">Username is required</div>
          <div *ngIf="f.username.errors.email">Invalid email id</div>
        </div>
      </div>
      <br />
      <div class="form-group">
        <label for="password" class="control-label required">Mac Address</label>
        <input type="password" formControlName="password" placeholder="mac address" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Mac Address is required</div>
        </div>
      </div>
      <br />
      <div class="form-group">
        <label for="confirmPassword" class="control-label required">Confirm Mac Address</label>
        <input type="password" formControlName="confirmPassword" placeholder="confirm mac address" autocomplete="off" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.confirmPassword.errors }" />
        <div *ngIf="submitted && f.confirmPassword.errors" class="invalid-feedback">
          <div *ngIf="f.confirmPassword.errors.required">Confirm Mac Address is required</div>
          <div class="alert alert-danger"
               *ngIf="f.confirmPassword.errors?.MatchPassword">Mac Address not match</div>
        </div>
      </div>
      <br />
      <div class="form-group">
        <label for="firstname" class="control-label required">First Name</label>
        <input type="text" formControlName="firstname" placeholder="first name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.firstname.errors }" />
        <div *ngIf="submitted && f.firstname.errors" class="invalid-feedback">
          <div *ngIf="f.firstname.errors.required">First name is required</div>
        </div>
      </div>
      <br />
      <div class="form-group">
        <label for="lastname" class="control-label required">Last Name</label>
        <input type="text" formControlName="lastname" placeholder="last name" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.lastname.errors }" />
        <div *ngIf="submitted && f.lastname.errors" class="invalid-feedback">
          <div *ngIf="f.lastname.errors.required">Last name is required</div>
        </div>
      </div>
      <br />
      <div class="form-group">
        <label></label>
        <button [disabled]="loading" type="submit" class="btn btn-primary" mat-stroked-button color="primary">Whitelist</button>
        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        <a routerLink="/login" id="signin" aria-label="Singn in instead">Go To Login</a>
      </div>
      <div *ngIf="error" class="alert alert-danger">{{error}}</div>
    </fieldset>
  </form>
</div>
