import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { first } from 'rxjs/operators';
import { PasswordValidation } from '../_helpers/PasswordValidation';

@Component({
  selector: 'app-whitelist',
  templateUrl: './whitelist.component.html',
  styleUrls: ['./whitelist.component.css']
})
export class WhitelistComponent implements OnInit {

  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email, Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$')]],
      password: ['', Validators.required],
      confirmPassword: ['', Validators.required],
      firstname: ['', Validators.required],
      lastname: ['', Validators.required]
    }, {
      validator: PasswordValidation.MatchPassword // your validation method
    });

  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.whitelist(this.f.username.value.toLowerCase(), this.f.password.value, this.f.firstname.value, this.f.lastname.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data != null && data.isSuccess) {
            this.error = "Request sent for Whitelisting successfully. Access will be granted after administrator approves it.";
            this.loading = false;
          }
          else {
            this.error = data.message;
            this.loading = false;
          }
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }


}
