<div style="padding-left:5px !important;padding-right:5px;">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div>
    <br />
    <h1>Filter Manufacturing Test data</h1>
  </div>
  <div class="mat-form-field--inline">
    <h1>Results Filters</h1>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="test_idFilter" #test_id name="test_id" placeholder="Test ID">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="mfg_timestampFilter" #mfg_timestamp name="mfg_timestamp" placeholder="MFG Timestamp">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="jlink_serialFilter" #jlink_serial name="jlink_serial" placeholder="Jlink Serial">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="uidFilter" #uid name="uid" placeholder="UID">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="upcFilter" #upc name="upc" placeholder="UPC">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="test_tatusFilter" #test_tatus name="test_tatus" placeholder="Test Status">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="rssiFilter" #rssi name="rssi" placeholder="RSSI">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="overall_currentFilter" #overall_current name="overall_current" placeholder="Overall Current">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="overall_statusFilter" #overall_status name="overall_status" placeholder="Overall Status">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="firmware_filenameFilter" #firmware_filename name="firmware_filename" placeholder="Firmware Filename">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="created_byFilter" #created_by name="created_by" placeholder="Created By">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="createdtime_utcFilter" #createdtime_utc name="createdtime_utc" placeholder="Created Time (UTC)">
    </mat-form-field>
    <p style="text-align:end">
      <button type="button" mat-stroked-button color="primary" (click)="exportAsExcel()">Export as Excel</button>
    </p>
  </div>

  <div #TABLE>
    <table mat-table [dataSource]="MyDataSource" matSort matSortStart="desc" class="mat-elevation-z8" style="width:100%;">
      <!-- ID Column -->
      <ng-container matColumnDef="test_id">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="test_id"> Test ID </th>
        <td mat-cell *matCellDef="let row"> {{row.test_id}} </td>
      </ng-container>

      <ng-container matColumnDef="mfg_timestamp">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="mfg_timestamp"> Manufacturing Timestamp </th>
        <td mat-cell *matCellDef="let row"> {{row.mfg_timestamp}} </td>
      </ng-container>

      <ng-container matColumnDef="jlink_serial">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="jlink_serial"> Jlink Serial </th>
        <td mat-cell *matCellDef="let row"> {{row.jlink_serial}} </td>
      </ng-container>

      <!-- Lap Column -->
      <ng-container matColumnDef="uid">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="uid"> UID </th>
        <td mat-cell *matCellDef="let row"> {{row.uid}} </td>
      </ng-container>

      <ng-container matColumnDef="upc">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="upc"> UPC </th>
        <td mat-cell *matCellDef="let row"> {{row.upc}} </td>
      </ng-container>

      <ng-container matColumnDef="test_tatus">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="test_tatus"> Test Status </th>
        <td mat-cell *matCellDef="let row"> {{row.test_tatus}} </td>
      </ng-container>

      <ng-container matColumnDef="rssi">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="rssi"> RSSI </th>
        <td mat-cell *matCellDef="let row"> {{row.rssi}} </td>
      </ng-container>

      <ng-container matColumnDef="overall_current">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="overall_current"> Overall Current </th>
        <td mat-cell *matCellDef="let row"> {{row.overall_current}} </td>
      </ng-container>

      <ng-container matColumnDef="overall_status">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="overall_status"> Overall/Post Status </th>
        <td mat-cell *matCellDef="let row"> {{row.overall_status}} </td>
      </ng-container>

      <ng-container matColumnDef="firmware_filename">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="firmware_filename"> Firmware Filename </th>
        <td mat-cell *matCellDef="let row"> {{row.firmware_filename}} </td>
      </ng-container>

      <ng-container matColumnDef="created_by">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="created_by"> Created By </th>
        <td mat-cell *matCellDef="let row"> {{row.created_by}} </td>
      </ng-container>

      <ng-container matColumnDef="createdtime_utc">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="createdtime_utc"> Created Time (UTC) </th>
        <td mat-cell *matCellDef="let row"> {{row.createdtime_utc}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
  </div>
  <mat-paginator class="paginator--centered" [pageSizeOptions]="[10, 30, 50,100]" showFirstLastButtons></mat-paginator>
</div>
