import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DataService } from '../service/data-service.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-firmwarefileupload',
  templateUrl: './firmwarefileupload.component.html',
  styleUrls: ['./firmwarefileupload.component.css']
})

export class FirmwarefileuploadComponent implements OnInit {

  isLoading = false;
  submitted = false;
  uploadForm : FormGroup;
  @ViewChild('firmwareFileInput') fileInput: ElementRef;
  @ViewChild('versionInput') versionInput: ElementRef;

  constructor(private dataService: DataService, private fb: FormBuilder, private SpinnerService: NgxSpinnerService) {
    this.createForm();
  }

  ngOnInit(): void {
  }

  get f() {
    return this.uploadForm.controls;
  }

  createForm() {
    this.uploadForm  = this.fb.group({
      version: ['', Validators.required],
      firmwarefile: null
    });
  }

  onFileChange(event) {
    let reader = new FileReader();
    if (event.target.files && event.target.files.length > 0) {
      let file = event.target.files[0];
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.uploadForm.get('firmwarefile').setValue({
          filename: file.name,
          filetype: file.type,
          value: (<string>reader.result).split(',')[1]
        })
      };
    }
  }

  onSubmit() {
    this.submitted = true;

    if (this.uploadForm.get('firmwarefile').value == null) {
      alert('Please select firmware file to be uploaded.');
      return false;
    }

    if (this.versionInput.nativeElement.value.trim() == "") {
      alert('Please enter firmware version number.');
      return false;
    }

    this.isLoading = true;
    this.SpinnerService.show();

    const formModel = this.uploadForm.get('firmwarefile').value;
    formModel.version = this.versionInput.nativeElement.value;
    
    // In a real-world app you'd have a http request / service call here like
    this.dataService.uploadFirmwareFile(formModel)
      .subscribe(
        res => {
          this.isLoading = false;
          this.SpinnerService.hide();
          alert('Firmware file uploaded successfully.');
          this.clearFile();
        },
        error => {
          this.isLoading = false;
          this.SpinnerService.hide();
          alert('Firmware file upload failed. Please try again...');
        }, () => {
          this.isLoading = false;
          this.SpinnerService.hide();
          this.clearFile();
        });
  }

  clearFile() {
    this.uploadForm.get('firmwarefile').setValue(null);
    this.fileInput.nativeElement.value = '';
  }

}
