import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { HttpClient,HttpHeaders } from '@angular/common/http';  
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { SearchObject } from '../model/SearchObject';
@Injectable()
export class DataService {
  private lapUrl = environment.url;
  token: string = ''
  constructor(private http: HttpClient) { }

  public getUser(keyValuesFilter, versionfilter, deviceidfilter, statusfilter, filterByReceived): Observable<any> {
    var url = this.lapUrl +"firmware";
    if (keyValuesFilter != null) {
      url = url + "?keyvalues=" + keyValuesFilter + "&filterbyreceived=" + filterByReceived + "&versionfilter=" + versionfilter + "&deviceidfilter=" + deviceidfilter + "&statusfilter=" + statusfilter;
    }

    console.log(url);
    return this.http.get(url).pipe(
      map((res) => res)
    );
  }

  public getUsers() {
    var url = this.lapUrl + "users";
    this.token =  "Bearer " + localStorage.getItem('token');
    const httpheaders = new HttpHeaders({
      'Content-Type': 'application/json',
      'Authorization': this.token
    })

    console.log(url);
    return this.http.get(url, { headers: httpheaders })
      .pipe(
        map(result => {
          return result;
        })
    );
  }

  public getJobUsers() {
    var url = this.lapUrl + "jobusers";
    console.log(url);
    return this.http.get(url)
      .pipe(
        map(result => {
          return result;
        })
    );
  }

  // public getWarehouseSRAPList(serialNumber) {
  //   //var names = selectedFileNames.includes('All') ? 'All' : selectedFileNames.toString();
  //   var url = this.lapUrl + "productmanufacturinginfo/SmartPurifiers?searchQuery=" + serialNumber + "&pageNumber=1&limit=20";

  //   console.log(url);
  //   return this.http.get(url).pipe(
  //     map((res) => res)
  //   );g
  // }

  // public getWarehouseSRAPList(serialnumber: string) {
  //   return this.http.post<any>(`${environment.url}productmanufacturinginfo/SmartPurifiers`, { serialnumber });
  // }

  public getWarehouseSRAPList(searchObject: SearchObject) {
    
    return this.http.post<any>(`${environment.url}productmanufacturinginfo/SmartPurifiers`, { searchObject });
  }


  public fetchwhitelistdevice(serialNumber) {
    //var names = selectedFileNames.includes('All') ? 'All' : selectedFileNames.toString();
    var url = this.lapUrl + "productmanufacturinginfo/Whitelist?serialNumber=" + serialNumber;

    console.log(url);
    return this.http.get(url).pipe(
      map((res) => res)
    );
  }

  public getSectigoFiles(dateFrom,dateTo) {
    var url = this.lapUrl + "sectigofiles";
    
    if (dateFrom != null && dateTo != null) {
      url = url + "?dateFrom=" + dateFrom + "&dateTo=" + dateTo;
    }
    
    console.log(url);
    return this.http.get(url)
      .pipe(
        map(result => {
          return result;
        })
      );
  }

  public uploadSerialNumberFile(formModel): Observable<any> {
    var url = this.lapUrl+"serialnumber";
    console.log(url);
    return this.http.post<any>(url, formModel);
  }

  public getSerialNumberFilesList(dateFrom,dateTo) {
    var url = this.lapUrl + "serialnumberfiles";
    if (dateFrom != null && dateTo != null) {
      url = url + "?dateFrom=" + dateFrom + "&dateTo=" + dateTo;
    }
    console.log(url);
    return this.http.get(url)
      .pipe(
        map(result => {
          return result;
        })
      );
  }
  
  public uploadFirmwareFile(formModel): Observable<any> {
    var url = this.lapUrl + "firmwarefile";
    console.log(url);
    return this.http.post<any>(url, formModel);
  }

  public uploadFirmwareLoaderFile(formModel): Observable<any> {
    var url = this.lapUrl + "FirmwareLoaderFiles";
    console.log(url);
    return this.http.post<any>(url, formModel);
  }

  public uploadRapDeviceLogFile(formModel): Observable<any> {
    var url = this.lapUrl + "rapdevicelogfiles";
    console.log(url);
    return this.http.post<any>(url, formModel);
  }

  public getRapDeviceLogFileData(fileurl): Observable<any> {
    return this.http.get(fileurl,{ responseType: 'text' });    
  }

  public ApproveRole(username,role): Observable<any> {
    var url = this.lapUrl + "users";
    console.log(url);
    return this.http.post<any>(url, { username: username,role:role });
  }

  public ApproveJobRole(username,role): Observable<any> {
    var url = this.lapUrl + "jobusers";
    console.log(url);
    return this.http.post<any>(url, { username: username,role:role });
  }

  public getSerialNumberData(dateFrom,dateTo,selectedFileNames) {
    var names = selectedFileNames.includes('All') ? 'All' : selectedFileNames.toString();
    var url = this.lapUrl + "serialnumber?fileNames=" + names+ "&dateFrom=" + dateFrom + "&dateTo=" + dateTo;;

    console.log(url);
    return this.http.get(url).pipe(
      map((res) => res)
    );
  }

  public getFirmwareFilesData() {
    var url = this.lapUrl + "firmwarefile";

    console.log(url);
    return this.http.get(url).pipe(
      map((res) => res)
    );
  }

  public getFilterFirmwareFilesData() {
    var url = this.lapUrl + "filter";

    console.log(url);
    return this.http.get(url).pipe(
      map((res) => res)
    );
  }

  public getFilterDashboardData() {
    var url = this.lapUrl + "filter/GetFilterDashboardData";

    console.log(url);
    return this.http.get(url).pipe(
      map((res) => res)
    );
  }

  public getRapLogFilesList() {
    var url = this.lapUrl + "raplogfiles";

    console.log(url);
    return this.http.get(url).pipe(
      map((res) => res)
    );
  }

  public getFirmwareLoaderFilesList(dateFrom,dateTo,selectedFileNames) {
    var names = selectedFileNames.includes('All') ? 'All' : selectedFileNames.toString();
    var url = this.lapUrl + "firmwareloaderfiles?fileNames=" + names + "&dateFrom=" + dateFrom + "&dateTo=" + dateTo;

    console.log(url);
    return this.http.get(url).pipe(
      map((res) => res)
    );
  }

  public getFirmwareLoaderFilesData(dateFrom,dateTo) {
    
    var url = this.lapUrl + "firmwareloader";

    if (dateFrom != null && dateTo != null) {
      url = url + "?dateFrom=" + dateFrom + "&dateTo=" + dateTo;
    }

    console.log(url);
    return this.http.get(url)
      .pipe(
        map(result => {
          return result;
        })
      );
  }
  
}
