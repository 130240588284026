<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

<br />
<br />
<div style="margin-left:5%;margin-right:40%;">
  <form [formGroup]="form" (ngSubmit)="onSubmit()">
    <fieldset style="border-radius:5px;">
      <legend>Upload firmware .log files </legend>
        <div class="control">
          <label class="control-label required">Choose .log file</label>
          <input type="file" accept=".txt,.log" multiple="multiple" id="firmwarefile" (change)="onFileChange($event)" #firmwareFileInput>
        </div>
        <br />
        <div class="control">
          <button type="submit" class="btn btn-default" mat-stroked-button color="primary" style="margin-right:10px;">Submit<i class="fa fa-spinner fa-spin fa-fw"></i></button>
          <button type="button" class="btn" (click)="clearFile()" mat-stroked-button color="primary">Clear file</button>
        </div>
</fieldset>
  </form>
</div>

