import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { MatTableDataSource } from '@angular/material/table';
import { AuthenticationService } from '../service/authentication.service';
import { DataService } from '../service/data-service.service';

@Component({ templateUrl: 'warehousesrapdetail.component.html' })
export class WarehousesrapdetailComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  //submitted = false;
  serialNumberValue: string;
  macAddressValue: string;
  bluetoothNameValue: string;
  isWareHouseDevice: boolean;
  firmwareVersion: string;
  firmwareUpdateStatusValue: boolean;
  comments: string;
  createdTS: string;
  modifiedTS: string;
  registrationId: string;
  deviceStatus: string;
  
  error = '';
  MyDataSource: any;

  // isAdmin: boolean=false;
  // isContributor: boolean = false;
  // isReader: boolean = false;

  // isFilterAdmin: boolean=false;
  // isFilterContributor: boolean = false;
  // isFilterReader: boolean = false;

  constructor(
    public dataService: DataService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      serialnumber: ['', Validators.required],
      macaddress: ['', Validators.required]
    });

    // reset login status
    //this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.serialNumberValue = this.route.snapshot.queryParams['serialNumber'] || '/';
    this.RenderDataTable();
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  RenderDataTable() {
    //this.isLoading = true;
    //this.SpinnerService.show();
    this.dataService.fetchwhitelistdevice(this.serialNumberValue)
      .subscribe(
        res => {
          this.MyDataSource = new MatTableDataSource();
          //this.isLoading = false;
          //this.SpinnerService.hide();
          this.MyDataSource.data = res;
          //this.MyDataSource.paginator = this.paginator;
          //this.MyDataSource.sort = this.sort;
          this.serialNumberValue = this.MyDataSource.data.response.serialNumber;
          this.macAddressValue = this.MyDataSource.data.response.macAddress;
          this.registrationId = this.MyDataSource.data.response.registrationId;
          this.bluetoothNameValue = this.MyDataSource.data.response.bluetoothIdentifier;
          this.isWareHouseDevice = this.MyDataSource.data.response.isWareHouseDevice;
          this.firmwareUpdateStatusValue = this.MyDataSource.data.response.firmwareUpdateStatus;
          this.firmwareVersion = this.MyDataSource.data.response.firmwareVersion;
          this.comments = this.MyDataSource.data.response.comments;
          this.createdTS = this.MyDataSource.data.response.createdTS;
          this.modifiedTS = this.MyDataSource.data.response.modifiedTS;

          if(this.isWareHouseDevice == false ) 
          {
            this.deviceStatus = 'Not Whitelisted';
          }
          else if( this.firmwareUpdateStatusValue == true && this.isWareHouseDevice == true ) 
          {
              this.deviceStatus = 'Firmware Upgraded';
          }
          else
          {
            this.deviceStatus = 'Ready for Firmware Upgrade';
          }
        },
        error => {
          //this.isLoading = false;
          //this.SpinnerService.hide();
          alert('There was an error while retrieving data !!!');
          console.log('There was an error while retrieving data !!!' + error);
        }, () => {
          //this.MyDataSource.sort = this.sort;
          //this.isLoading = false;
          //this.SpinnerService.hide();
        });
  }


  // fetchWhitelistDevice() {
  //   this.submitted = true;

  //   // stop here if form is invalid
  //   // if (this.loginForm.invalid) {
  //   //   return;
  //   // }

  //   this.loading = true;

  //   this.authenticationService.whitelistserialnumber(this.f.serialnumber.value)
  //   .pipe(first())
  //   .subscribe(
  //     data => {
  //       if (data != null && data.status == 200) {
  //         this.error = data.response;
  //         //"Request sent for Serialnumber Whitelisting successfully. Access will be granted after administrator approves it.";
  //         this.loading = false;
  //       }
  //       else {
  //         this.error = data.response;
  //         this.loading = false;
  //       }
  //     },
  //     error => {
  //       this.error = error;
  //       this.loading = false;
  //     });

  // }

  // navigateOnRole()
  // {
  //   if(this.isAdmin)
  //   {
  //     //this.router.navigate([this.returnUrl]);
  //     this.router.navigate(['/firmwarehistory']);
  //   }

  //   if(this.isContributor)
  //   {
  //     //this.router.navigate([this.returnUrl]);
  //     this.router.navigate(['/serialnumberlist']);
  //   }

  //   if(this.isReader)
  //   {
  //     //this.router.navigate([this.returnUrl]); 
  //     this.router.navigate(['/serialnumberlist']);
  //   }

  //   if(this.isFilterAdmin)
  //   {
  //     this.router.navigate(['/filterdashboard']);
  //   }

  //   if(this.isFilterContributor)
  //   {
  //     this.router.navigate(['/filterfirmwarefileslist']);
  //   }

  //   if(this.isFilterReader)
  //   {
  //     this.router.navigate(['/filterfirmwarefileslist']);
  //   }

  // }
  // assignRole()
  // {
  //   this.isAdmin = localStorage.getItem('role') == 'admin' ? true : false;
  //   this.isContributor = localStorage.getItem('role') == 'contributor' ? true : false;
  //   this.isReader = localStorage.getItem('role') == 'reader' ? true : false;

  //   this.isFilterAdmin = localStorage.getItem('role') == 'filteradmin' ? true : false;
  //   this.isFilterContributor = localStorage.getItem('role') == 'filtercontributor' ? true : false;
  //   this.isFilterReader = localStorage.getItem('role') == 'filterreader' ? true : false;

  // }
}
