<div style="padding-left:5px !important;padding-right:5px;">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div>
    <br />
    <h1>Serial number data</h1>
  </div>
  <div class="mat-form-field--inline">
    <mat-form-field>
      <input type="date" matInput class="form-field" [formControl]="fcDateFrom" #DateFrom name="nDateFrom" placeholder="Date From">
    </mat-form-field>
    <mat-form-field>
      <input type="date" matInput class="form-field" [formControl]="fcDateTo" #DateTo name="nDateTo" placeholder="Date To">
    </mat-form-field>
    <button type="button" mat-stroked-button (click)="GetFiles()" color="primary" >Get files</button>
    <mat-form-field>
      <mat-label>Select file name</mat-label>
      <mat-select multiple name="selected_FileName" [(ngModel)]="selected_FileName">
        <mat-option *ngFor="let item of fileNamesList" [value]="item.fileName" title="{{item.fileDataCount}} files">{{item.fileName}}</mat-option>
      </mat-select>
    </mat-form-field>
      <p style="display:inline !important;">
        <button type="button" mat-stroked-button color="primary" (click)="selectedFileNameChangeAction()">Show files</button>
      </p>
  </div>

    <div class="mat-form-field--inline">
      <mat-form-field>
        <input matInput class="form-field" [formControl]="deviceIdFilter" #DeviceId name="DeviceId" placeholder="DeviceId Filter">
      </mat-form-field>
      <mat-form-field>
        <input matInput class="form-field" [formControl]="commsVersionFilter" #Comms_Ver name="Comms_Ver" placeholder="Comms Version Filter">
      </mat-form-field>
      <mat-form-field>
        <input matInput class="form-field" [formControl]="cert_CommonNameFilter" #Cert_CommonName name="Cert_CommonName" placeholder="Cert common Name Filter">
      </mat-form-field>
      <p style="text-align:end">
        <button type="button" mat-stroked-button color="primary" (click)="exportAsExcel()">Export as Excel</button>
      </p>
    </div>

    <!--<div class="mat-form-field--inline">
      <mat-form-field>
        <input matInput class="form-field" [formControl]="deviceKeyValues" placeholder="Multiple keys seperated by comma e.g tags.deviceId='02217e24a01e',tags.devicetype='smartrap',properties.reported.fwVersionCommsBoard='1.0.6.0'">
      </mat-form-field>

      <button type="button" mat-stroked-button color="primary" (click)="search()">Search</button>
      <button type="button" mat-stroked-button color="primary" (click)="searchFilter()">Get all scheduled</button>
    </div>-->

    <div #TABLE>
      <table mat-table [dataSource]="MyDataSource" matSort matSortStart="desc" class="mat-elevation-z8" style="width:100%;">
        <!-- ID Column -->
        <ng-container matColumnDef="deviceId">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="deviceId"> DeviceId </th>
          <td mat-cell *matCellDef="let row"> {{row.deviceId}} </td>
        </ng-container>

        <ng-container matColumnDef="_sTime">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="_sTime"> Time </th>
          <td mat-cell *matCellDef="let row"> {{row._sTime}} </td>
        </ng-container>

        <!-- Lap Column -->
        <ng-container matColumnDef="macAddress">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="macAddress"> Mac Address </th>
          <td mat-cell *matCellDef="let row"> {{row.macAddress}} </td>
        </ng-container>

        <ng-container matColumnDef="sys_Sn">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="sys_Sn"> Sys_Sn </th>
          <td mat-cell *matCellDef="let row"> {{row.sys_Sn}} </td>
        </ng-container>

        <ng-container matColumnDef="raP_Ver">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="raP_Ver"> RAP Version </th>
          <td mat-cell *matCellDef="let row"> {{row.raP_Ver}} </td>
        </ng-container>

        <!-- Shortcut Column -->
        <ng-container matColumnDef="comms_Ver">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="comms_Ver"> Comms Version </th>
          <td mat-cell *matCellDef="let row"> {{row.comms_Ver}} </td>
        </ng-container>

        <ng-container matColumnDef="cert_CommonName">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="cert_CommonName"> Cert Common Name </th>
          <td mat-cell *matCellDef="let row"> {{row.cert_CommonName}} </td>
        </ng-container>

        <ng-container matColumnDef="fileName">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="fileName"> File Name </th>
          <td mat-cell *matCellDef="let row"> {{row.fileName}} </td>
        </ng-container>

        <ng-container matColumnDef="uploadTime">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="uploadTime"> Upload date time </th>
          <td mat-cell *matCellDef="let row"> {{row.uploadTime}} </td>
        </ng-container>

        <ng-container matColumnDef="isDuplicate">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="isDuplicate"> Duplicate </th>
          <td mat-cell *matCellDef="let row">
            <span [style.color]="row.isDuplicate ? 'red' : null">
              {{row.isDuplicate==true?"Yes":"No"}}
            </span>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
    </div>
    <mat-paginator class="paginator--centered" [pageSizeOptions]="[10, 30, 50,100]" showFirstLastButtons></mat-paginator>
  </div>
