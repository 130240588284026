import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../service/data-service.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import { FormControl } from '@angular/forms'
import { NgxSpinnerService } from "ngx-spinner";
import { firmwarefilemodel } from '../model/firmwarefilemodel';

@Component({
  selector: 'app-firmwarefileslist',
  templateUrl: './firmwarefileslist.component.html',
  styleUrls: ['./firmwarefileslist.component.css']
})
export class FirmwarefileslistComponent implements OnInit {

  displayedColumns = ['version', 'fileName', 'fileUrl', 'creationDateTime'];
  MyDataSource: any;
  isLoading = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  versionFilter = new FormControl();
  fileNameFilter = new FormControl();
  creationDateTimeFilter = new FormControl();

  filteredValues = { Version: '', FileName: '', CreationDateTime: '' };

  constructor(public dataService: DataService, private SpinnerService: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.RenderDataTable();

    this.versionFilter.valueChanges.subscribe((versionFilterValue) => {
      this.filteredValues['Version'] = versionFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.fileNameFilter.valueChanges.subscribe((fileNameFilterValue) => {
      this.filteredValues['FileName'] = fileNameFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.creationDateTimeFilter.valueChanges.subscribe((creationDateTimeFilterValue) => {
      this.filteredValues['CreationDateTime'] = creationDateTimeFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });
  }

  ngAfterViewInit() {
    //this.MyDataSource.paginator = this.paginator;
  }


  RenderDataTable() {
    this.isLoading = true;
    this.SpinnerService.show();
    this.dataService.getFirmwareFilesData()
      .subscribe(
        res => {
          this.MyDataSource = new MatTableDataSource();
          this.isLoading = false;
          this.SpinnerService.hide();
          this.MyDataSource.data = res;
          this.MyDataSource.paginator = this.paginator;
          this.MyDataSource.sort = this.sort;
        },
        error => {
          this.isLoading = false;
          this.SpinnerService.hide();
          alert('There was an error while retrieving data !!!');
          console.log('There was an error while retrieving data !!! ' + error);
        }, () => {
          this.MyDataSource.filterPredicate = this.customFilterPredicate();
          this.MyDataSource.sort = this.sort;
          this.isLoading = false;
          this.SpinnerService.hide();
        });
  }

  customFilterPredicate() {
    const myFilterPredicate = function (data: firmwarefilemodel, filter: string): boolean {
      let searchString = JSON.parse(filter);
      let versionFound = data.version.toString().trim().toLowerCase().indexOf(searchString.Version.trim().toLowerCase()) !== -1
      let fileNameFound = data.fileName != null && data.fileName.toString().trim().indexOf(searchString.FileName.trim()) !== -1
      let creationDateTimeFound = data.creationDateTime != null && data.creationDateTime.toString().trim().indexOf(searchString.CreationDateTime.trim()) !== -1
      if (searchString.topFilter) {
        return versionFound || fileNameFound || creationDateTimeFound
      } else {
        return versionFound && fileNameFound && creationDateTimeFound
      }
    }
    return myFilterPredicate;
  }

  public exportAsExcel(): void {
    const workSheet = XLSX.utils.json_to_sheet(this.MyDataSource.filteredData, { header: this.displayedColumns });
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'FirmwareFilesData');
    XLSX.writeFile(workBook, 'FirmwareFilesData.xlsx');
  }

}

