import { Component, OnInit, AfterViewInit} from '@angular/core';
import { Router } from '@angular/router';
import { Observable, BehaviorSubject } from 'rxjs';
import { AuthenticationService } from './service/authentication.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {
  isLoggedIn$: Observable<boolean>;
  roleadmin$: Observable<boolean>;
  rolecontributor$: Observable<boolean>;
  rolereader$: Observable<boolean>;
  rolefilteradmin$: Observable<boolean>;
  rolefiltercontributor$: Observable<boolean>;
  rolefilterreader$: Observable<boolean>;

  //Warehouse
  rolewarehouseadmin$: Observable<boolean>;
  rolewarehousecontributor$: Observable<boolean>;
  rolewarehousereader$: Observable<boolean>;

  role: string;
  currentuser: any;

  constructor(private router: Router, private authenticationService: AuthenticationService) {
    this.currentuser = JSON.parse(localStorage.getItem('currentUser'));
    this.role = localStorage.getItem('role');
    this.isLoggedIn$ = this.authenticationService.isLoggedIn;

    this.roleadmin$ = this.authenticationService.isAdminUser;
    var isAdmin = localStorage.getItem('role') == 'admin' ? true : false;
    this.authenticationService.isAdminRole.next(isAdmin);

    this.rolefilteradmin$ = this.authenticationService.isFilterAdminUser;
    var isFilterAdmin = localStorage.getItem('role') == 'filteradmin' ? true : false;
    this.authenticationService.isFilterAdminRole.next(isFilterAdmin);

    this.rolewarehouseadmin$ = this.authenticationService.isWarehouseAdminUser;
    var isWarehouseAdmin = localStorage.getItem('role') == 'warehouseadmin' ? true : false;
    this.authenticationService.isWarehouseAdminRole.next(isWarehouseAdmin);

    this.rolecontributor$ = this.authenticationService.isAdminUser;
    var isContributor = localStorage.getItem('role') == 'contributor' ? true : false;
    this.authenticationService.isContributorRole.next(isContributor);

    this.rolefiltercontributor$ = this.authenticationService.isFilterContributorUser;
    var isFilterContributor = localStorage.getItem('role') == 'filtercontributor' ? true : false;
    this.authenticationService.isFilterContributorRole.next(isFilterContributor);

    this.rolewarehousecontributor$ = this.authenticationService.isWarehouseContributorUser;
    var isWarehouseContributor = localStorage.getItem('role') == 'warehousecontributor' ? true : false;
    this.authenticationService.isWarehouseContributorRole.next(isWarehouseContributor);

    this.rolereader$ = this.authenticationService.isAdminUser;
    var isReader = localStorage.getItem('role') == 'reader' ? true : false;
    this.authenticationService.isReaderRole.next(isReader);

    this.rolefilterreader$ = this.authenticationService.isFilterReaderUser;
    var isFilterReader = localStorage.getItem('role') == 'filterreader' ? true : false;
    this.authenticationService.isFilterReaderRole.next(isFilterReader);

    this.rolewarehousereader$ = this.authenticationService.isWarehouseReaderUser;
    var isWarehouseReader = localStorage.getItem('role') == 'warehousereader' ? true : false;
    this.authenticationService.isWarehouseReaderRole.next(isWarehouseReader);

  }

  ngOnInit() {
    this.isLoggedIn$ = this.authenticationService.isLoggedIn;

    this.roleadmin$ = this.authenticationService.isAdminRole;
    this.rolecontributor$ = this.authenticationService.isContributorRole;
    this.rolereader$ = this.authenticationService.isReaderRole;

    this.rolefilteradmin$ = this.authenticationService.isFilterAdminRole;
    this.rolefiltercontributor$ = this.authenticationService.isFilterContributorRole;
    this.rolefilterreader$ = this.authenticationService.isFilterReaderRole;

    this.rolewarehouseadmin$ = this.authenticationService.isWarehouseAdminRole;
    this.rolewarehousecontributor$ = this.authenticationService.isWarehouseContributorRole;
    this.rolewarehousereader$ = this.authenticationService.isWarehouseReaderRole;

    this.roleadmin$.subscribe(res => {
      if (res == true)
      {
        this.router.navigate(['/firmwarehistory']);
      }
      //else {
      //  this.router.navigate(['/serialnumberlist']);
      //}
    });

    this.rolecontributor$.subscribe(res => {
      if (res == true)
      {
        this.router.navigate(['/serialnumberlist']);
      }
      //else {
      //  this.router.navigate(['/serialnumberlist']);
      //}
    });

    this.rolereader$.subscribe(res => {
      if (res == true)
      {
        this.router.navigate(['/serialnumberlist']);
      }
      //else {
      //  this.router.navigate(['/serialnumberlist']);
      //}
    });

    this.rolefilteradmin$.subscribe(res => {
      if (res == true)
      {
        this.router.navigate(['/filterdashboard']);
      }
      //else {
      //  this.router.navigate(['/filterfirmwarefileslist']);
      //}
    });

    this.rolefiltercontributor$.subscribe(res => {
      if (res == true)
      {
        this.router.navigate(['/filterfirmwarefileslist']);
      }
      //else {
      //  this.router.navigate(['/filterfirmwarefileslist']);
      //}
    });

    this.rolefilterreader$.subscribe(res => {
      if (res == true)
      {
        this.router.navigate(['/filterfirmwarefileslist']);
      }
      //else {
      //  this.router.navigate(['/filterfirmwarefileslist']);
      //}
    });

    //warehouse
    this.rolewarehouseadmin$.subscribe(res => {
      if (res == true)
      {
        this.router.navigate(['/warehousesraplist']);
      }
      //else {
      //  this.router.navigate(['/filterfirmwarefileslist']);
      //}
    });

    this.rolewarehousecontributor$.subscribe(res => {
      if (res == true)
      {
        this.router.navigate(['/warehousesraplist']);
      }
      //else {
      //  this.router.navigate(['/filterfirmwarefileslist']);
      //}
    });

    this.rolewarehousereader$.subscribe(res => {
      if (res == true)
      {
        this.router.navigate(['/warehousesraplist']);
      }
      //else {
      //  this.router.navigate(['/filterfirmwarefileslist']);
      //}
    });


    }

  ngAfterViewInit() {
    //this.MyDataSource.paginator = this.paginator;
  }

  onLogout() {
    this.authenticationService.logout();
    this.router.navigate(['/login']);
  }

}
