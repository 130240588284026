<div style="padding-left:5px !important;padding-right:5px;">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div>
    <br />
    <h1>Sectigo certificates</h1>
  </div>
  <div class="mat-form-field--inline">
    <mat-form-field>
      <input type="date" matInput class="form-field" [formControl]="fcDateFrom" #DateFrom name="nDateFrom" placeholder="Date From">
    </mat-form-field>
    <mat-form-field>
      <input type="date" matInput class="form-field" [formControl]="fcDateTo" #DateTo name="nDateTo" placeholder="Date To">
    </mat-form-field>
      <button type="button" mat-stroked-button (click)="GetSectigoFilesList()" color="primary" >Search</button>
  </div>
  <div #TABLE>
    <table mat-table [dataSource]="MyDataSource" matSort matSortStart="desc" class="mat-elevation-z8" style="width:100%;">
      <!-- ID Column -->
      <ng-container matColumnDef="batchId">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="batchId"> batch Id </th>
        <td mat-cell *matCellDef="let row"> {{row.batchId}} </td>
      </ng-container>


      <ng-container matColumnDef="batchName">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="batchName"> Batch name </th>
        <td mat-cell *matCellDef="let row"> {{row.batchName}} </td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="creationDate"> Creation date</th>
        <td mat-cell *matCellDef="let row"> {{row.creationDate}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
  </div>
  <mat-paginator class="paginator--centered" [pageSizeOptions]="[10, 30, 50,100]" showFirstLastButtons></mat-paginator>
</div>
