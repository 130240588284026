import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../service/data-service.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import { FormControl } from '@angular/forms'
import { NgxSpinnerService } from "ngx-spinner";
import { filterdashboarddatamodel } from '../model/firmwarefilemodel';

@Component({
  selector: 'app-filterdashboard',
  templateUrl: './filterdashboard.component.html',
  styleUrls: ['./filterdashboard.component.css']
})
export class FilterDashboardComponent implements OnInit {

  //displayedColumns = ['test_id', 'mfg_timestamp', 'jlink_serial', 'uid', 'upc', 'test_tatus', 'rssi', 'overall_current', 'overall_status', 'firmware_filename', 'created_by', 'createdtime_utc'];
  //displayedColumns = ['test_id_min', 'test_id_max', 'test_id_count', 'test_tatus_passcount', 'test_tatus_failcount', 'test_tatus_othercount', 'overall_status_0', 'overall_status_1', 'overall_status_2', 'overall_status_othercount', 'mfg_timestamp_min', 'mfg_timestamp_max', 'overall_current_min', 'overall_current_max', 'overall_current_avg', 'created_by', 'createdtime_utc_max', 'createdtime_utc_min', 'firstname', 'lastname'];
  //displayedColumns = ['test_id_min', 'test_id_max', 'test_id_count', 'test_tatus_passcount', 'test_tatus_failcount'];
  //displayedColumns = ['test_id_min', 'test_id_max', 'test_id_count', 'test_tatus_passcount', 'test_tatus_failcount', 'test_tatus_othercount'];
  displayedExcelColumns = ['test_id_min', 'test_id_max', 'test_id_count', 'test_tatus_passcount', 'test_tatus_failcount', 'test_tatus_othercount', 'overall_status_0', 'overall_status_1', 'overall_status_2', 'overall_status_othercount', 'mfg_timestamp_min', 'mfg_timestamp_max', 'overall_current_min', 'overall_current_max', 'overall_current_avg', 'created_by', 'createdtime_utc_max', 'createdtime_utc_min', 'firstname', 'lastname'];
  displayedColumns = ['test_id_max', 'test_id_count', 'test_tatus_failcount', 'overall_status_othercount', 'overall_current_max', 'mfg_timestamp_max', 'createdtime_utc_max', 'created_by'];
  MyDataSource: any;
  MyDataSourceRows: any;
  MyDataSourceRowsAggregate: any;
  isLoading = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  
  test_id_minFilter = new FormControl();
  test_id_maxFilter = new FormControl();
  test_id_countFilter = new FormControl();
  test_tatus_passcountFilter = new FormControl();
  test_tatus_failcountFilter = new FormControl();
  test_tatus_othercountFilter = new FormControl();
  overall_status_0Filter = new FormControl();
  overall_status_1Filter = new FormControl();
  overall_status_2Filter = new FormControl();
  overall_status_othercountFilter = new FormControl();
  mfg_timestamp_minFilter = new FormControl();
  mfg_timestamp_maxFilter = new FormControl();
  overall_current_minFilter = new FormControl();
  overall_current_maxFilter = new FormControl();
  overall_current_avgFilter = new FormControl();
  created_byFilter = new FormControl();
  createdtime_utc_maxFilter = new FormControl();
  createdtime_utc_minFilter = new FormControl();
  firstnameFilter = new FormControl();
  lastnameFilter = new FormControl();

filteredValues = { test_id_min: '', test_id_max: '', test_id_count: '', test_tatus_passcount: '', test_tatus_failcount: '', test_tatus_othercount: '', overall_status_0: '', overall_status_1: '', overall_status_2: '', overall_status_othercount: '', mfg_timestamp_min: '', mfg_timestamp_max: '', overall_current_min: '', overall_current_max: '', overall_current_avg: '', created_by: '', createdtime_utc_max: '', createdtime_utc_min: '', firstname: '', lastname: ''};

  constructor(public dataService: DataService, private SpinnerService: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.RenderDataTable();

    this.test_id_minFilter.valueChanges.subscribe((test_id_minFilterValue) => {
      console.log("test_id_min:" + test_id_minFilterValue)
      this.filteredValues['test_id_min'] = test_id_minFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.test_id_maxFilter.valueChanges.subscribe((test_id_maxFilterValue) => {
      console.log("test_id_max:" + test_id_maxFilterValue)
      this.filteredValues['test_id_max'] = test_id_maxFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.test_id_countFilter.valueChanges.subscribe((test_id_countFilterValue) => {
      console.log("test_id_count:" + test_id_countFilterValue)
      this.filteredValues['test_id_count'] = test_id_countFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.test_tatus_passcountFilter.valueChanges.subscribe((test_tatus_passcountFilterValue) => {
      console.log("test_tatus_passcount:" + test_tatus_passcountFilterValue)
      this.filteredValues['test_tatus_passcount'] = test_tatus_passcountFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.test_tatus_failcountFilter.valueChanges.subscribe((test_tatus_failcountFilterValue) => {
      console.log("test_tatus_failcount:" + test_tatus_failcountFilterValue)
      this.filteredValues['test_tatus_failcount'] = test_tatus_failcountFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.test_tatus_othercountFilter.valueChanges.subscribe((test_tatus_othercountFilterValue) => {
      console.log("test_tatus_othercount:" + test_tatus_othercountFilterValue)
      this.filteredValues['test_tatus_othercount'] = test_tatus_othercountFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.overall_status_0Filter.valueChanges.subscribe((overall_status_0FilterValue) => {
      console.log("overall_status_0:" + overall_status_0FilterValue)
      this.filteredValues['overall_status_0'] = overall_status_0FilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.overall_status_1Filter.valueChanges.subscribe((overall_status_1FilterValue) => {
      console.log("overall_status_1:" + overall_status_1FilterValue)
      this.filteredValues['overall_status_1'] = overall_status_1FilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.overall_status_2Filter.valueChanges.subscribe((overall_status_2FilterValue) => {
      console.log("overall_status_2:" + overall_status_2FilterValue)
      this.filteredValues['overall_status_2'] = overall_status_2FilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.overall_status_othercountFilter.valueChanges.subscribe((overall_status_othercountFilterValue) => {
      console.log("overall_status_othercount:" + overall_status_othercountFilterValue)
      this.filteredValues['overall_status_othercount'] = overall_status_othercountFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.mfg_timestamp_minFilter.valueChanges.subscribe((mfg_timestamp_minFilterValue) => {
      console.log("mfg_timestamp_min:" + mfg_timestamp_minFilterValue)
      this.filteredValues['mfg_timestamp_min'] = mfg_timestamp_minFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.mfg_timestamp_maxFilter.valueChanges.subscribe((mfg_timestamp_maxFilterValue) => {
      console.log("mfg_timestamp_max:" + mfg_timestamp_maxFilterValue)
      this.filteredValues['mfg_timestamp_max'] = mfg_timestamp_maxFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.overall_current_minFilter.valueChanges.subscribe((overall_current_minFilterValue) => {
      console.log("overall_current_min:" + overall_current_minFilterValue)
      this.filteredValues['overall_current_min'] = overall_current_minFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.overall_current_maxFilter.valueChanges.subscribe((overall_current_maxFilterValue) => {
      console.log("overall_current_max:" + overall_current_maxFilterValue)
      //this.filteredValues['overall_current_max'] = overall_current_maxFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.overall_current_avgFilter.valueChanges.subscribe((overall_current_avgFilterValue) => {
      console.log("overall_current_avg:" + overall_current_avgFilterValue)
      this.filteredValues['overall_current_avg'] = overall_current_avgFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.created_byFilter.valueChanges.subscribe((created_byFilterValue) => {
      console.log("created_by:" + created_byFilterValue)
      this.filteredValues['created_by'] = created_byFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.createdtime_utc_maxFilter.valueChanges.subscribe((createdtime_utc_maxFilterValue) => {
      console.log("createdtime_utc_max:" + createdtime_utc_maxFilterValue)
      this.filteredValues['createdtime_utc_max'] = createdtime_utc_maxFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.createdtime_utc_minFilter.valueChanges.subscribe((createdtime_utc_minFilterValue) => {
      console.log("createdtime_utc_min:" + createdtime_utc_minFilterValue)
      this.filteredValues['createdtime_utc_min'] = createdtime_utc_minFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.firstnameFilter.valueChanges.subscribe((firstnameFilterValue) => {
      console.log("firstname:" + firstnameFilterValue)
      this.filteredValues['firstname'] = firstnameFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });

    this.lastnameFilter.valueChanges.subscribe((lastnameFilterValue) => {
      console.log("lastname:" + lastnameFilterValue)
      this.filteredValues['lastname'] = lastnameFilterValue.trim();
      //this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      this.MyDataSourceRows.filter = JSON.stringify(this.filteredValues);
    });
    
  }

  ngAfterViewInit() {
    //this.MyDataSource.paginator = this.paginator;
  }


  RenderDataTable() {
    this.isLoading = true;
    this.SpinnerService.show();
    this.dataService.getFilterDashboardData()
      .subscribe(
        res => {
          this.MyDataSource = new MatTableDataSource();
          this.MyDataSourceRows = new MatTableDataSource();
          this.MyDataSourceRowsAggregate = new MatTableDataSource();

          this.isLoading = false;
          this.SpinnerService.hide();

          this.MyDataSource.data = res;
          this.MyDataSourceRows.data = this.MyDataSource.data.filterDashboardDataList;
          this.MyDataSourceRowsAggregate.data = this.MyDataSource.data.filterDashboardDataAggregate;

          //this.MyDataSource.paginator = this.paginator;
          this.MyDataSourceRows.paginator = this.paginator;

          //this.MyDataSource.sort = this.sort;
          this.MyDataSourceRows.sort = this.sort;
        },
        error => {
          this.isLoading = false;
          this.SpinnerService.hide();
          alert('There was an error while retrieving data !!!');
          console.log('There was an error while retrieving data !!! ' + error);
        }, () => {
          //this.MyDataSource.filterPredicate = this.customFilterPredicate();
          this.MyDataSourceRows.filterPredicate = this.customFilterPredicate();

          //this.MyDataSource.sort = this.sort;
          this.MyDataSourceRows.sort = this.sort;

          this.isLoading = false;
          this.SpinnerService.hide();
        });
  }

  customFilterPredicate() {
    const myFilterPredicate = function (data: filterdashboarddatamodel, filter: string): boolean {
      let searchString = JSON.parse(filter);
      let test_id_minFound = data.test_id_min.toString().trim().toLowerCase().indexOf(searchString.test_id_min.trim().toLowerCase()) !== -1
      let test_id_maxFound = data.test_id_max.toString().trim().toLowerCase().indexOf(searchString.test_id_max.trim().toLowerCase()) !== -1
      let test_id_countFound = data.test_id_count != null && data.test_id_count.toString().trim().indexOf(searchString.test_id_count.trim()) !== -1
      let test_tatus_passcountFound = data.test_tatus_passcount != null && data.test_tatus_passcount.toString().trim().indexOf(searchString.test_tatus_passcount.trim()) !== -1
      let test_tatus_failcountFound = data.test_tatus_failcount.toString().trim().toLowerCase().indexOf(searchString.test_tatus_failcount.trim().toLowerCase()) !== -1
      let test_tatus_othercountFound = data.test_tatus_othercount.toString().trim().toLowerCase().indexOf(searchString.test_tatus_othercount.trim().toLowerCase()) !== -1
      let overall_status_0Found = data.overall_status_0.toString().trim().toLowerCase().indexOf(searchString.overall_status_0.trim().toLowerCase()) !== -1
      let overall_status_1Found = data.overall_status_1.toString().trim().toLowerCase().indexOf(searchString.overall_status_1.trim().toLowerCase()) !== -1
      let overall_status_2Found = data.overall_status_2.toString().trim().toLowerCase().indexOf(searchString.overall_status_2.trim().toLowerCase()) !== -1
      let overall_status_othercountFound = data.overall_status_othercount.toString().trim().toLowerCase().indexOf(searchString.overall_status_othercount.trim().toLowerCase()) !== -1
      let mfg_timestamp_minFound = data.mfg_timestamp_min.toString().trim().toLowerCase().indexOf(searchString.mfg_timestamp_min.trim().toLowerCase()) !== -1
      let mfg_timestamp_maxFound = data.mfg_timestamp_max.toString().trim().toLowerCase().indexOf(searchString.mfg_timestamp_max.trim().toLowerCase()) !== -1
      let overall_current_minFound = data.overall_current_min.toString().trim().toLowerCase().indexOf(searchString.overall_current_min.trim().toLowerCase()) !== -1
      let overall_current_maxFound = data.overall_current_max.toString().trim().toLowerCase().indexOf(searchString.overall_current_max.trim().toLowerCase()) !== -1
      //let overall_current_avgFound = data.overall_current_avg.toString().trim().toLowerCase().indexOf(searchString.overall_current_avg.trim().toLowerCase()) !== -1
      let created_byFound = data.created_by.toString().trim().toLowerCase().indexOf(searchString.created_by.trim().toLowerCase()) !== -1
      let createdtime_utc_maxFound = data.createdtime_utc_max.toString().trim().toLowerCase().indexOf(searchString.createdtime_utc_max.trim().toLowerCase()) !== -1
      let createdtime_utc_minFound = data.createdtime_utc_min.toString().trim().toLowerCase().indexOf(searchString.createdtime_utc_min.trim().toLowerCase()) !== -1
      let firstnameFound = data.firstname.toString().trim().toLowerCase().indexOf(searchString.firstname.trim().toLowerCase()) !== -1
      let lastnameFound = data.lastname.toString().trim().toLowerCase().indexOf(searchString.lastname.trim().toLowerCase()) !== -1

      if (searchString.topFilter) {
        //|| overall_current_avgFound 
        return test_id_minFound || test_id_maxFound || test_id_countFound || test_tatus_passcountFound || test_tatus_failcountFound || test_tatus_othercountFound || overall_status_0Found || overall_status_1Found || overall_status_2Found || overall_status_othercountFound || mfg_timestamp_minFound || mfg_timestamp_maxFound || overall_current_minFound || overall_current_maxFound || created_byFound || createdtime_utc_maxFound || createdtime_utc_minFound || firstnameFound || lastnameFound
      } else {
        //&& overall_current_avgFound 
        return test_id_minFound  && test_id_maxFound && test_id_countFound && test_tatus_passcountFound && test_tatus_failcountFound && test_tatus_othercountFound && overall_status_0Found && overall_status_1Found && overall_status_2Found && overall_status_othercountFound && mfg_timestamp_minFound && mfg_timestamp_maxFound && overall_current_minFound && overall_current_maxFound && created_byFound && createdtime_utc_maxFound && createdtime_utc_minFound && firstnameFound && lastnameFound
      }
    }
    return myFilterPredicate;
  }

  public exportAsExcel(): void {
    //const workSheet = XLSX.utils.json_to_sheet(this.MyDataSource.filteredData, { header: this.displayedColumns });
    const workSheet = XLSX.utils.json_to_sheet(this.MyDataSourceRows.filteredData, { header: this.displayedExcelColumns });
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'FilterManufacturingDashboard');
    XLSX.writeFile(workBook, 'FilterManufacturingTestDashboardData.xlsx');
  }

}

