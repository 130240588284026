import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { MatMenuModule } from '@angular/material/menu';
import {MatIconModule} from '@angular/material/icon';
import {
  MatSidenavModule
} from '@angular/material/sidenav';
import {
  MatToolbarModule
} from '@angular/material/toolbar';
import { MatTableModule } from '@angular/material/table';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSortModule } from '@angular/material/sort';
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DataService } from './service/data-service.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import {
  MatButtonModule
} from '@angular/material/button';
import { FlexLayoutModule } from '@angular/flex-layout';
import { NgxSpinnerModule } from "ngx-spinner";
import { ClipboardModule } from 'ngx-clipboard';
import { BasicAuthInterceptor } from './_helpers/basic-auth.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SerialnumberComponent } from './serialnumber/serialnumber.component';
import { SerialnumberlistComponent } from './serialnumberlist/serialnumberlist.component';
import { WarehousesraplistComponent } from './warehousesraplist/warehousesraplist.component';
import { WarehousesrapdetailComponent } from './warehousesrapdetail/warehousesrapdetail.component';
import { FirmwarehistoryComponent } from './firmwarehistory/firmwarehistory.component';
import { FirmwarefileuploadComponent } from './firmwarefileupload/firmwarefileupload.component';
import { FirmwareloaderfilesuploadComponent } from './firmwareloaderfilesupload/firmwareloaderfilesupload.component';
import { FirmwareLoaderFilesComponent } from './firmwareloaderfiles/firmwareloaderfiles.component';
import { FirmwarefileslistComponent } from './firmwarefileslist/firmwarefileslist.component';
import { FilterFirmwarefileslistComponent } from './filterfirmwarefileslist/filterfirmwarefileslist.component';
import { FilterDashboardComponent } from './filterdashboard/filterdashboard.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { WhitelistComponent } from './whitelist/whitelist.component';
import { WhitelistSerialnumberComponent } from './whitelistserialnumber/whitelistserialnumber.component';
import { UserslistComponent } from './userslist/userslist.component';
import { JobUserslistComponent } from './jobuserslist/jobuserslist.component';
import { ApproveuserComponent } from './modals/approveuser/approveuser.component';
import { ApprovejobuserComponent } from './modals/approvejobuser/approvejobuser.component';
import { SectigofilesComponent } from './sectigofiles/sectigofiles.component';
import { RapdevicelogfileuploadComponent } from './rapdevicelogfileupload/rapdevicelogfileupload.component';
import { RapdevicelogfilesComponent } from './rapdevicelogfiles/rapdevicelogfiles.component';

@NgModule({
  declarations: [
    AppComponent,
    SerialnumberComponent,
    SerialnumberlistComponent,
    WarehousesraplistComponent,
    WarehousesrapdetailComponent,
    FirmwarehistoryComponent,
    FirmwarefileuploadComponent,
    FirmwarefileslistComponent,
    FilterFirmwarefileslistComponent,
    FilterDashboardComponent,
    FirmwareloaderfilesuploadComponent,
    FirmwareLoaderFilesComponent,
    LoginComponent,
    RegisterComponent,
    WhitelistComponent,
    WhitelistSerialnumberComponent,
    UserslistComponent,
    JobUserslistComponent,
    ApproveuserComponent,
    ApprovejobuserComponent,
    SectigofilesComponent,
    RapdevicelogfileuploadComponent,
    RapdevicelogfilesComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    MatTableModule,
    MatFormFieldModule,
    MatInputModule,
    MatPaginatorModule,
    MatSortModule,
    MatButtonModule,
    MatMenuModule,
    MatIconModule, MatSidenavModule, MatToolbarModule,
    MatDialogModule, MatSelectModule,
    FlexLayoutModule,
    NgxSpinnerModule,
    ClipboardModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    HttpClientModule
  ],
  exports: [
    MatFormFieldModule,
    MatInputModule
  ],
  providers: [DataService, { provide: HTTP_INTERCEPTORS, useClass: BasicAuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }],
  bootstrap: [AppComponent]
})
export class AppModule {
   }
