<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
  <p style="color: white"> Loading... </p>
</ngx-spinner>

<br />
<br />
<div style="margin-left:5%;margin-right:50%;">
  <form [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
    <fieldset style="border-radius:5px;">
      <legend>Upload comms firmware file </legend>
      <div class="control">
        <label class="control-label required">Comms firmware version</label>
        <input type="text" id="version" #versionInput placeholder="Comms firmware version" formControlName="version" [ngClass]="{ 'is-invalid': submitted && f.version.errors }">
        <div *ngIf="submitted && f.version.errors" class="invalid-feedback">
          <div *ngIf="f.version.errors.required">Version is required</div>
          </div>
        </div>
        <div class="control">
          <label class="control-label required">Choose firmware file</label><input type="file" accept=".bin" id="firmwarefile" (change)="onFileChange($event)" #firmwareFileInput>
        </div>
        <br />
        <div class="control">
          <button type="submit" class="btn btn-default" mat-stroked-button color="primary" style="margin-right:10px;">Submit<i class="fa fa-spinner fa-spin fa-fw"></i></button>
          <button type="button" class="btn" (click)="clearFile()" mat-stroked-button color="primary">Clear file</button>
        </div>
</fieldset>
  </form>
</div>

