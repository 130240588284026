<div class="alert alert-info">
</div>
<div style="margin-left:5%;margin-right:50%;">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" method="post">
    <fieldset style="border-radius:5px;">
      <legend>Login</legend>
      <div class="form-group">
        <label for="username" class="control-label required">Username</label>
        <input type="email" formControlName="username" placeholder="email" class="form-control" [ngClass]="{ 'is-invalid': submitted && f.username.errors }" />
        <div *ngIf="submitted && f.username.errors" class="invalid-feedback">
          <div *ngIf="f.username.errors.required">Username is required</div>
          <div *ngIf="f.username.errors.email">Invalid email id</div>
        </div>
      </div>
      <br />
      <div class="form-group">
        <label for="password" class="control-label required">Password</label>
        <input type="password" formControlName="password" placeholder="password" class="form-control" autocomplete="off" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" />
        <div *ngIf="submitted && f.password.errors" class="invalid-feedback">
          <div *ngIf="f.password.errors.required">Password is required</div>
        </div>
      </div>
      <br />
      <div class="form-group">
        <label></label>
        <button [disabled]="loading" class="btn btn-primary" mat-stroked-button color="primary">Login</button>
        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
        <div>
        No Account? <a routerLink="/register" id="signup" aria-label="Create a Microsoft account">Create one!</a>
      </div>
        <div>
        Not Whitelisted? <a routerLink="/whitelist" id="whitelist" aria-label="Create a whitelist account">Create one!</a>
      </div>
      </div>
      <div *ngIf="error" class="alert alert-danger">{{error}}</div>
    </fieldset>
  </form>
  </div>
