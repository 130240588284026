<div style="padding-left:5px !important;padding-right:5px;">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div>
    <br />
    <h1>Filter Test Upload Job Users</h1>
  </div>

  <div #TABLE>
    <table mat-table [dataSource]="MyDataSource" matSort matSortStart="desc" class="mat-elevation-z8" style="width:100%;">
      <!-- ID Column -->
      <ng-container matColumnDef="username">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="username"> User name </th>
        <td mat-cell *matCellDef="let row"> {{row.username}} </td>
      </ng-container>

      <ng-container matColumnDef="firstname">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="firstname"> First name</th>
        <td mat-cell *matCellDef="let row"> {{row.firstname}} </td>
      </ng-container>

      <ng-container matColumnDef="lastname">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="lastname"> Last name</th>
        <td mat-cell *matCellDef="let row"> {{row.lastname}} </td>
      </ng-container>

      <ng-container matColumnDef="role">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="role"> role </th>
        <td mat-cell *matCellDef="let row"> {{row.role}} </td>
      </ng-container>

      <!-- Lap Column -->
      <ng-container matColumnDef="action">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef> Action </th>
        <td mat-cell *matCellDef="let row">
          <button type="button" mat-stroked-button color="primary" (click)="ApproveJobUser(row.username,row.role)" value="Approve" >Assign Role</button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>
  </div>
  <mat-paginator class="paginator--centered" [pageSizeOptions]="[10, 30, 50,100]" showFirstLastButtons></mat-paginator>
</div>
