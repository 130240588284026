import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public loggedIn = new BehaviorSubject<boolean>(false);
  public token = new BehaviorSubject<string>('');

  public isAdminRole = new BehaviorSubject<boolean>(false);
  public isContributorRole = new BehaviorSubject<boolean>(false);
  public isReaderRole = new BehaviorSubject<boolean>(false);

  public isFilterAdminRole = new BehaviorSubject<boolean>(false);
  public isFilterContributorRole = new BehaviorSubject<boolean>(false);
  public isFilterReaderRole = new BehaviorSubject<boolean>(false);

  public isWarehouseAdminRole = new BehaviorSubject<boolean>(false);
  public isWarehouseContributorRole = new BehaviorSubject<boolean>(false);
  public isWarehouseReaderRole = new BehaviorSubject<boolean>(false);

  isAdmin: boolean=false;
  isContributor: boolean = false;
  isReader: boolean = false;

  isFilterAdmin: boolean=false;
  isFilterContributor: boolean = false;
  isFilterReader: boolean = false;

  isWarehouseAdmin: boolean=false;
  isWarehouseContributor: boolean = false;
  isWarehouseReader: boolean = false;

    constructor(private http: HttpClient) { }

    login(username: string, password: string) {
        return this.http.post<any>(`${environment.url}authenticate`, { username, password })
          .pipe(map(result => {
              // login successful if there's a user in the response
              if (result != null && result.isSuccess) {
                // store user details and basic auth credentials in local storage 
                // to keep user logged in between page refreshes
                localStorage.setItem('currentUser', JSON.stringify(result.data));
                localStorage.setItem('role', result.data[0].role);
                localStorage.setItem('token',result.token);
                localStorage.setItem('expiration',result.expiration);
                this.loggedIn.next(true);
                this.token.next(result.token);

                this.isAdmin = localStorage.getItem('role') == 'admin' ? true : false;
                this.isContributor = localStorage.getItem('role') == 'contributor' ? true : false;
                this.isReader = localStorage.getItem('role') == 'reader' ? true : false;

                this.isFilterAdmin = localStorage.getItem('role') == 'filteradmin' ? true : false;
                this.isFilterContributor = localStorage.getItem('role') == 'filtercontributor' ? true : false;
                this.isFilterReader = localStorage.getItem('role') == 'filterreader' ? true : false;

                this.isWarehouseAdmin = localStorage.getItem('role') == 'warehouseadmin' ? true : false;
                this.isWarehouseContributor = localStorage.getItem('role') == 'warehousecontributor' ? true : false;
                this.isWarehouseReader = localStorage.getItem('role') == 'warehousereader' ? true : false;

                this.isAdminRole.next(this.isAdmin);
                this.isContributorRole.next(this.isContributor);
                this.isReaderRole.next(this.isReader);
                
                this.isFilterAdminRole.next(this.isFilterAdmin);
                this.isFilterContributorRole.next(this.isFilterContributor);
                this.isFilterReaderRole.next(this.isFilterReader);

                this.isWarehouseAdminRole.next(this.isWarehouseAdmin);
                this.isWarehouseContributorRole.next(this.isWarehouseContributor);
                this.isWarehouseReaderRole.next(this.isWarehouseReader);

              }
              else {
                this.loggedIn.next(false);
                this.token.next('');

                this.isAdminRole.next(false);
                this.isContributorRole.next(false);
                this.isReaderRole.next(false);

                this.isFilterAdminRole.next(false);
                this.isFilterContributorRole.next(false);
                this.isFilterReaderRole.next(false);

                this.isWarehouseAdminRole.next(false);
                this.isWarehouseContributorRole.next(false);
                this.isWarehouseReaderRole.next(false);

                return result;
              }

            return result;
            }));
  }
 
  register(username: string, password: string, firstname: string, lastname: string) {
    return this.http.post<any>(`${environment.url}register`, { username, password, firstname, lastname });
  }

  whitelist(username: string, password: string, firstname: string, lastname: string) {
    return this.http.post<any>(`${environment.url}ManufacturingWhitelist`, { username, password, firstname, lastname });
  }

  whitelistserialnumber(serialnumber: string) {
    return this.http.post<any>(`${environment.url}productmanufacturinginfo/Whitelist`, { serialnumber });
  }

  get isLoggedIn() {
    if (localStorage.getItem('currentUser')) {
      this.loggedIn.next(true);
    }

    return this.loggedIn.asObservable();
  }

  get userToken() {
    return this.token.asObservable();
  }
  get isAdminUser() {
    return this.isAdminRole.asObservable();
  }

  get isFilterAdminUser() {
    return this.isFilterAdminRole.asObservable();
  }

  get isWarehouseAdminUser() {
    return this.isWarehouseAdminRole.asObservable();
  }

  get isContributorUser() {
    return this.isContributorRole.asObservable();
  }

  get isFilterContributorUser() {
    return this.isFilterContributorRole.asObservable();
  }

  get isWarehouseContributorUser() {
    return this.isWarehouseContributorRole.asObservable();
  }

  get isReaderUser() {
    return this.isReaderRole.asObservable();
  }

  get isFilterReaderUser() {
    return this.isFilterReaderRole.asObservable();
  }

  get isWarehouseReaderUser() {
    return this.isWarehouseReaderRole.asObservable();
  }

  logout() {
    this.loggedIn.next(false);
    this.token.next('');
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    localStorage.removeItem('role');
    }
}
