import { Component, OnInit, ViewChild } from '@angular/core';
import { DataService } from '../service/data-service.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import { FormControl } from '@angular/forms'
import { NgxSpinnerService } from "ngx-spinner";
import { raplogfilemodel } from '../model/firmwarefilemodel';

@Component({
  selector: 'app-rapdevicelogfiles',
  templateUrl: './rapdevicelogfiles.component.html',
  styleUrls: ['./rapdevicelogfiles.component.css']
})
export class RapdevicelogfilesComponent implements OnInit {

  displayedColumns = ['filename', 'fileurl', 'uploadtime'];
  MyDataSource: any;
  isLoading = false;
  filePreviewData = '';
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  fileNameFilter = new FormControl();

  filteredValues = { FileName: ''};

  constructor(public dataService: DataService, private SpinnerService: NgxSpinnerService) {
  }

  ngOnInit(): void {
    this.RenderDataTable();

    this.fileNameFilter.valueChanges.subscribe((fileNameFilterValue) => {
      this.filteredValues['FileName'] = fileNameFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });
  }

  ngAfterViewInit() {
    //this.MyDataSource.paginator = this.paginator;
  }


  RenderDataTable() {
    this.isLoading = true;
    this.SpinnerService.show();
    this.dataService.getRapLogFilesList()
      .subscribe(
        res => {
          this.MyDataSource = new MatTableDataSource();
          this.isLoading = false;
          this.SpinnerService.hide();
          this.MyDataSource.data = res;
          this.MyDataSource.paginator = this.paginator;
          this.MyDataSource.sort = this.sort;
        },
        error => {
          this.isLoading = false;
          this.SpinnerService.hide();
          alert('There was an error while retrieving data !!!');
          console.log('There was an error while retrieving data !!! ' + error);
        }, () => {
          this.MyDataSource.filterPredicate = this.customFilterPredicate();
          this.MyDataSource.sort = this.sort;
          this.isLoading = false;
          this.SpinnerService.hide();
        });
  }

  customFilterPredicate() {
    const myFilterPredicate = function (data: raplogfilemodel, filter: string): boolean {
      let searchString = JSON.parse(filter);
      let fileNameFound = data.filename != null && data.filename.toString().trim().indexOf(searchString.FileName.trim()) !== -1
      if (searchString.topFilter) {
        return fileNameFound
      } else {
        return fileNameFound
      }
    }
    return myFilterPredicate;
  }

  public exportAsExcel(): void {
    const workSheet = XLSX.utils.json_to_sheet(this.MyDataSource.filteredData, { header: this.displayedColumns });
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'RapLogFilesList');
    XLSX.writeFile(workBook, 'RapLogFilesListData.xlsx');
  }

  public getRapDeviceLogFileData(fileurl) {
    //window.open(fileurl, '_blank');
    this.isLoading = true;
    this.SpinnerService.show();
    this.dataService.getRapDeviceLogFileData(fileurl).subscribe(
      res => {
        this.isLoading = false;
        this.SpinnerService.hide();
        this.filePreviewData = res;
      },
      error => {
        this.isLoading = false;
        this.SpinnerService.hide();
        alert('There was an error while retrieving data !!!');
        console.log('There was an error while retrieving data !!! ' + error);
      }, () => {
        this.isLoading = false;
        this.SpinnerService.hide();
      });
  }

}

