import { Component, ElementRef, ViewChild, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { DataService } from '../service/data-service.service';
import { NgxSpinnerService } from 'ngx-spinner';

export interface FileData {
  filename: string;
  filetype: string;
  value: string;
}

@Component({
  selector: 'app-rapdevicelogfileupload',
  templateUrl: './rapdevicelogfileupload.component.html',
  styleUrls: ['./rapdevicelogfileupload.component.css']
})

export class RapdevicelogfileuploadComponent implements OnInit {

  isLoading = false;
  submitted = false;
  form: FormGroup;
  myFiles: FileData[] = [];
  @ViewChild('firmwareFileInput') fileInput: ElementRef;

  constructor(private dataService: DataService, private fb: FormBuilder, private SpinnerService: NgxSpinnerService) {
    this.createForm();
  }

  ngOnInit(): void {
  }

  get f() {
    return this.form.controls;
  }

  createForm() {
    this.form = this.fb.group({
      name: ['', Validators.required],
      firmwarefile: null
    });
  }

  onFileChange(event) {
    if (event.target.files && event.target.files.length > 0) {
      for (var i = 0; i < event.target.files.length; i++) {
        let file = event.target.files[i];
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          this.myFiles.push({
            filename: file.name,
            filetype: file.type,
            value: (<string>reader.result).split(',')[1]
          });
        };
      }

      this.form.get('firmwarefile').setValue(this.myFiles);
    }
  }

  onSubmit() {
    if (this.myFiles.length <= 0) {
      alert('Please select RAP device log file to be uploaded.');
      return false;
    }

    this.isLoading = true;
    this.SpinnerService.show();

    const formModel = this.form.get('firmwarefile').value;

    // In a real-world app you'd have a http request / service call here like
    this.dataService.uploadRapDeviceLogFile(formModel)
      .subscribe(
        res => {
          this.isLoading = false;
          this.SpinnerService.hide();
          this.clearFile();

          if (res.isSuccess) {
            alert('RAP device log file uploaded successfully.');
          }
          else {
            alert(res.message);
          }

        },
        error => {
          this.isLoading = false;
          this.SpinnerService.hide();
          alert('There was an error while retrieving data !!!');
          console.log('There was an error while retrieving data !!!' + error);
        }, () => {
          this.isLoading = false;
          this.SpinnerService.hide();
          this.clearFile();
        });

    this.clearFile();
  }

  clearFile() {
    this.form.get('firmwarefile').setValue(null);
    this.fileInput.nativeElement.value = '';
    this.myFiles = [];
  }

}
