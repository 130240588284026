import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { DataService } from '../service/data-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { FormControl } from '@angular/forms'

@Component({
  selector: 'app-sectigofiles',
  templateUrl: './sectigofiles.component.html',
  styleUrls: ['./sectigofiles.component.css']
})
export class SectigofilesComponent implements OnInit {
  displayedColumns = ['batchId','batchName','creationDate' ];
  MyDataSource: any;
  isLoading = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  fcDateFrom = new FormControl();
  fcDateTo = new FormControl();

  constructor(public dataService: DataService, private SpinnerService: NgxSpinnerService, public router: Router) {
  }

  ngOnInit(): void {
    //this.RenderDataTable();
  }

  ngAfterViewInit() {
    //this.MyDataSource.paginator = this.paginator;
  }

GetSectigoFilesList() {
  var msg = "";
  var dtFrm = "";
  dtFrm = this.fcDateFrom.value;

  var dtTo = "";
  dtTo = this.fcDateTo.value;

  if (dtFrm == undefined || dtFrm == null || dtFrm.length <=0 ) {
    msg += "From Date";
  }
  else if (dtTo == undefined || dtTo == null || dtTo.length <=0) {
    if(msg.length > 0)
    msg += " and "
     msg += "To Date";
  }
  else {
    this.RenderDataTable();
  }
  if(msg.length > 0)
  {
    msg = "Select " + msg;
    alert(msg);
  }
}
  RenderDataTable() {
    this.isLoading = true;
    this.SpinnerService.show();
    var dateFrom = this.fcDateFrom.value == null ? "" : this.fcDateFrom.value.trim();
    var dateTo = this.fcDateTo.value == null ? "" : this.fcDateTo.value.trim();
    this.dataService.getSectigoFiles(dateFrom,dateTo)
      .subscribe(
        res => {
          this.MyDataSource = new MatTableDataSource();
          this.isLoading = false;
          this.SpinnerService.hide();
          this.MyDataSource.data = res;
          this.MyDataSource.paginator = this.paginator;
          this.MyDataSource.sort = this.sort;
        },
        error => {
          this.isLoading = false;
          this.SpinnerService.hide();
          alert('There was an error while retrieving data !!!');
          console.log('There was an error while retrieving data !!!' + error);
        }, () => {
          this.MyDataSource.sort = this.sort;
          this.isLoading = false;
          this.SpinnerService.hide();
        });
  }

}
