<h2 mat-dialog-title>Assign role for '{{this.username}}'</h2>

<div mat-dialog-content>

  <p>Select role</p>
  <mat-form-field>
    <mat-label>Role</mat-label>
    <mat-select placeholder="RoleData"
                [(ngModel)]="selectedValue" name="roleselect">
      <mat-option *ngFor="let role of roles"
                  [value]="role.value">
        {{role.display}}
      </mat-option>
    </mat-select>
  </mat-form-field>

</div>

<div mat-dialog-actions>
  <button mat-button (click)="onApprove()" class="btn btn-primary" mat-stroked-button color="primary">Approve</button>
  <button mat-button [mat-dialog-close]="data.username" cdkFocusInitial class="btn btn-primary" mat-stroked-button color="primary">Close</button>
</div>
