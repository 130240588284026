import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { DataService } from '../service/data-service.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import { FormControl } from '@angular/forms'
import { NgxSpinnerService } from "ngx-spinner";
import { firmwareloaderfilemodel } from '../model/firmwarefilemodel';

@Component({
  selector: 'app-firmwareloaderfiles',
  templateUrl: './firmwareloaderfiles.component.html',
  styleUrls: ['./firmwareloaderfiles.component.css']
})
export class FirmwareLoaderFilesComponent implements OnInit, AfterViewInit {

  displayedColumns = ['mac_Address', 'certificate_FlashTime', 'certificate_FileName', 'sectigo_BatchID', 'status', 'amazon_Certificate_ID', 'fileName','uploadTime','isDuplicate'];
  MyDataSource: any;
  selected_FileName: string;
  isLoading = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  fileNamesList: any;
  macAddressFilter = new FormControl();
  
  filteredValues = { Mac_Address: ''};

  constructor(public dataService: DataService, private SpinnerService: NgxSpinnerService) {
  }

  fcDateFrom = new FormControl();
  fcDateTo = new FormControl();

  ngOnInit(): void {
    //this.RenderListOfSerialNumberFiles();
  }

  ngAfterViewInit() {
    //this.MyDataSource.paginator = this.paginator;
  }
  ValidateDate() {
    var msg = "";
    var dtFrm = "";
    dtFrm = this.fcDateFrom.value;
  
    var dtTo = "";
    dtTo = this.fcDateTo.value;
  
    if (dtFrm == undefined || dtFrm == null || dtFrm.length <=0 ) {
      msg += "From Date";
    }
    
    if (dtTo == undefined || dtTo == null || dtTo.length <=0) {
      if(msg.length > 0)
      msg += " and "
       msg += "To Date";
    }
    if(msg.length > 0)
    {
      msg = "Select " + msg;
    }
    return msg;
  }
  GetFiles() {
    var msg = this.ValidateDate();
    if(msg.length > 0)
    {
      alert(msg);
    }
    else {
      this.RenderListOfSerialNumberFiles();
    }
  }
    
  RenderListOfSerialNumberFiles() {
    this.isLoading = true;
    this.SpinnerService.show();
    var dateFrom = this.fcDateFrom.value == null ? "" : this.fcDateFrom.value.trim();
    var dateTo = this.fcDateTo.value == null ? "" : this.fcDateTo.value.trim();
    this.dataService.getFirmwareLoaderFilesData(dateFrom,dateTo)
      .subscribe(
        res => {
          this.isLoading = false;
          this.SpinnerService.hide();
          this.fileNamesList = res;
        },
        error => {
          this.isLoading = false;
          this.SpinnerService.hide();
          alert('There was an error while retrieving list of firmware loader files !!!');
          console.log('There was an error while retrieving data !!!' + error);
        }, () => {
          this.isLoading = false;
          this.SpinnerService.hide();
        });
  }

  selectedFileNameChangeAction() {
    var msg = this.ValidateDate();
    if (this.selected_FileName != undefined && msg.length <= 0) {
      this.RenderDataTable(this.selected_FileName);

      this.macAddressFilter.valueChanges.subscribe((macAddressFilterValue) => {
        this.filteredValues['Mac_Address'] = macAddressFilterValue.trim();
        this.MyDataSource.filter = JSON.stringify(this.filteredValues);
      });
    }
    else {
      msg += '\r\nPlease select at least one file from list.' 
      alert(msg);
    }
  }

  RenderDataTable(selectedFiledNames) {
    this.isLoading = true;
    this.SpinnerService.show();
    var dateFrom = this.fcDateFrom.value == null ? "" : this.fcDateFrom.value.trim();
    var dateTo = this.fcDateTo.value == null ? "" : this.fcDateTo.value.trim();

    this.dataService.getFirmwareLoaderFilesList(dateFrom,dateTo,selectedFiledNames)
      .subscribe(
        res => {
          this.MyDataSource = new MatTableDataSource();
          this.isLoading = false;
          this.SpinnerService.hide();
          this.MyDataSource.data = res;
          this.MyDataSource.paginator = this.paginator;
          this.MyDataSource.sort = this.sort;
        },
        error => {
          this.isLoading = false;
          this.SpinnerService.hide();
          alert('There was an error while retrieving data !!!');
          console.log('There was an error while retrieving data !!!' + error);
        }, () => {
          this.MyDataSource.filterPredicate = this.customFilterPredicate();
          this.MyDataSource.sort = this.sort;
          this.isLoading = false;
          this.SpinnerService.hide();
        });
  }

  customFilterPredicate() {
    const myFilterPredicate = function (data: firmwareloaderfilemodel, filter: string): boolean {
      let searchString = JSON.parse(filter);
      let mac_addressFound = data.mac_Address.toString().trim().toLowerCase().indexOf(searchString.Mac_Address.trim().toLowerCase()) !== -1
      
      if (searchString.topFilter) {
        return mac_addressFound
      } else {
        return mac_addressFound
      }
    }
    return myFilterPredicate;
  }

  public exportAsExcel(): void {
    const workSheet = XLSX.utils.json_to_sheet(this.MyDataSource.filteredData, { header: this.displayedColumns });
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'FirmwareLoaderData');
    XLSX.writeFile(workBook, 'FirmwareLoaderFileData.xlsx');
  }

}
