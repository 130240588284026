
  <div style="padding-left:5px !important;padding-right:5px;">
    <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
      <p style="color: white"> Loading... </p>
    </ngx-spinner>
    <div>
      <br />
      <h1>Firmware Update History</h1>
    </div>

    <div class="mat-form-field--inline">
      <mat-form-field>
        <input matInput class="form-field" [formControl]="deviceKeyValues" placeholder="Multiple keys seperated by comma e.g tags.deviceId='02217e24a01e',tags.devicetype='smartrap',properties.reported.fwVersionCommsBoard='1.0.6.0'">
      </mat-form-field>

      <button type="button" mat-stroked-button color="primary" (click)="search()">Search</button>
      <button type="button" mat-stroked-button color="primary" (click)="searchFilter()">Get scheduled</button>
    </div>

    <div class="mat-form-field--inline">
      <mat-form-field>
        <input matInput class="form-field" [formControl]="deviceIdFilter" #deviceId name="deviceId" placeholder="DeviceId Filter">
      </mat-form-field>
      <mat-form-field>
        <input matInput class="form-field" [formControl]="versionFilter" #version name="version" placeholder="Version Filter">
      </mat-form-field>
      <mat-form-field>
        <input matInput class="form-field" [formControl]="statusFilter" #status name="status" placeholder="Status Filter">
      </mat-form-field>
      <p style="text-align:end">
        <button type="button" mat-stroked-button color="primary" (click)="exportAsExcel()">Export as Excel</button>
      </p>
    </div>


    <div #TABLE>
      <table mat-table [dataSource]="MyDataSource" matSort matSortStart="desc" class="mat-elevation-z8">
        <!-- ID Column -->
        <ng-container matColumnDef="deviceId">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="deviceId"> deviceId </th>
          <td mat-cell *matCellDef="let row "> {{row.deviceId}} </td>
        </ng-container>

        <ng-container matColumnDef="userName">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="userName"> user name </th>
          <td mat-cell *matCellDef="let row "> {{row.userName}} </td>
        </ng-container>

        <!-- Lap Column -->
        <ng-container matColumnDef="version">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="version"> version </th>
          <td mat-cell *matCellDef="let row"> {{row.version}} </td>
        </ng-container>

        <ng-container matColumnDef="oldVersion">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="oldVersion"> old version </th>
          <td mat-cell *matCellDef="let row"> {{row.oldVersion}} </td>
        </ng-container>

        <ng-container matColumnDef="email">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="status"> user </th>
          <td mat-cell *matCellDef="let row" [style.color]="row.status"> {{row.status}} </td>
        </ng-container>

        <!-- Shortcut Column -->
        <ng-container matColumnDef="status">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="status"> status </th>
          <td mat-cell *matCellDef="let row" [style.color]="row.status"> {{row.status}} </td>
        </ng-container>

        <!-- Start Column -->
        <ng-container matColumnDef="updateDate">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="updateDate"> update date [CST] </th>
          <td mat-cell *matCellDef="let row"> {{row.updateDate|date:'medium':'CST'}} </td>
        </ng-container>

        <ng-container matColumnDef="updateEndDate">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="updateEndDate"> update end date [CST] </th>
          <td mat-cell *matCellDef="let row"><div *ngIf="row.updateEndDate!=0"> {{row.updateEndDate|date:'medium':'CST'}} </div></td>
        </ng-container>

        <ng-container matColumnDef="onlineStatus">
          <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="onlineStatus"> online status </th>
          <td mat-cell *matCellDef="let row" [style.color]="row.onlineStatus"> {{row.onlineStatus}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
    </div>
    <mat-paginator class="paginator--centered" [pageSizeOptions]="[10, 30, 50,100]" showFirstLastButtons></mat-paginator>
  </div>
