import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { DataService } from '../service/data-service.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import * as XLSX from 'xlsx';
import { FormControl } from '@angular/forms'
import { UpdateStatus } from '../model/updatestatus';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-firmwarehistory',
  templateUrl: './firmwarehistory.component.html',
  styleUrls: ['./firmwarehistory.component.css']
})
export class FirmwarehistoryComponent implements OnInit, AfterViewInit {

  displayedColumns = ['deviceId', 'userName', 'version', 'oldVersion', 'status', 'updateDate', 'updateEndDate', 'onlineStatus'];
  MyDataSource: any;
  isLoading = false;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  //@ViewChild('version') versionElement: ElementRef;
  //@ViewChild('deviceId') deviceIdElement: ElementRef;
  //@ViewChild('status') statusElement: ElementRef;

  deviceIdFilter = new FormControl();
  versionFilter = new FormControl();
  statusFilter = new FormControl();
  deviceKeyValues = new FormControl();

  filteredValues = { deviceId: '', version: '', status: '' };

  constructor(public dataService: DataService, private SpinnerService: NgxSpinnerService) {
  }

  ngOnInit() {

    //this.RenderDataTable("");
    this.deviceIdFilter.valueChanges.subscribe((deviceIdFilterValue) => {
      this.filteredValues['deviceId'] = deviceIdFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.versionFilter.valueChanges.subscribe((versionFilterValue) => {
      this.filteredValues['version'] = versionFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

    this.statusFilter.valueChanges.subscribe((statusFilterValue) => {
      this.filteredValues['status'] = statusFilterValue.trim();
      this.MyDataSource.filter = JSON.stringify(this.filteredValues);
    });

  }

  ngAfterViewInit() {
    //this.MyDataSource.paginator = this.paginator;
  }

  customFilterPredicate() {
    const myFilterPredicate = function (data: UpdateStatus, filter: string): boolean {
      let searchString = JSON.parse(filter);
      let deviceIdFound = data.deviceId.toString().trim().toLowerCase().indexOf(searchString.deviceId.trim().toLowerCase()) !== -1
      let versionFound = data.version != null && data.version.toString().trim().indexOf(searchString.version.trim()) !== -1
      let statusFound = data.status != null && data.status.toString().trim().indexOf(searchString.status.trim()) !== -1
      if (searchString.topFilter) {
        return deviceIdFound || versionFound || statusFound
      } else {
        return deviceIdFound && versionFound && statusFound
      }
    }
    return myFilterPredicate;
  }

  RenderDataTable(keyValuesFilter, versionfilter, deviceidfilter, statusfilter, filterByReceived) {
    this.dataService.getUser(keyValuesFilter, versionfilter, deviceidfilter, statusfilter, filterByReceived)
      .subscribe(
        res => {
          this.MyDataSource = new MatTableDataSource();
          this.isLoading = false;
          this.SpinnerService.hide();
          this.MyDataSource.data = res;
          this.MyDataSource.paginator = this.paginator;
          this.MyDataSource.sort = this.sort;
        },
        error => {
          this.isLoading = false;
          this.SpinnerService.hide();
          alert('There was an error while retrieving data !!!');
          console.log('There was an error while retrieving data !!! ' + error);
        }, () => {
          this.MyDataSource.filterPredicate = this.customFilterPredicate();
          this.MyDataSource.sort = this.sort;
          this.isLoading = false;
          this.SpinnerService.hide();
        });
  }

  public exportAsExcel(): void {
    const workSheet = XLSX.utils.json_to_sheet(this.MyDataSource.filteredData, { header: this.displayedColumns });
    const workBook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, 'FirmwareUpdateHistory');
    XLSX.writeFile(workBook, 'FirmwareUpdateHistory.xlsx');
  }

  public search(): void {
    this.SpinnerService.show();
    this.isLoading = true;
    var versionFilterData = this.versionFilter.value == null ? "" : this.versionFilter.value.trim();
    var deviceIdFilterData = this.deviceIdFilter.value == null ? "" : this.deviceIdFilter.value.trim();
    var statusFilterData = this.statusFilter.value == null ? "" : this.statusFilter.value.trim();

    this.RenderDataTable(this.deviceKeyValues.value, versionFilterData, deviceIdFilterData, statusFilterData, false);
  }

  public searchFilter(): void {
    if (this.deviceKeyValues.value != null) {
      this.SpinnerService.show();
      this.isLoading = true;
      var versionFilterData = this.versionFilter.value == null ? "" : this.versionFilter.value.trim();
      var deviceIdFilterData = this.deviceIdFilter.value == null ? "" : this.deviceIdFilter.value.trim();
      var statusFilterData = this.statusFilter.value == null ? "" : this.statusFilter.value.trim();

      this.RenderDataTable(this.deviceKeyValues.value, versionFilterData, deviceIdFilterData, statusFilterData, true);
    }
    else {
      alert('Input is missing. Please provide keys to be searched.');
    }
  }

}
