<div class="alert alert-info">
</div>
<div style="margin-left:5%;margin-right:60%;">
  <form [formGroup]="loginForm" (ngSubmit)="onSubmit()"  method="post">
    <fieldset style="border-radius:5px;">
      <legend>Whitelist Smart Air Purifier Serial Number</legend>
      <br />
      <div class="form-group">
        <label for="serialnumber" class="control-label required">Serial Number</label>
        <input type="text" formControlName="serialnumber" class="form-control"
               placeholder="serial number" #search
               [ngClass]="{ 'is-invalid': submitted && f.serialnumber.errors }" />
        <div *ngIf="submitted && f.serialnumber.errors" class="invalid-feedback">
          <div *ngIf="f.serialnumber.errors.required">Serial number is required</div>
        </div>
      </div>
      <br />      
      <div class="form-group">
        <label></label>
        <button [disabled]="loading" type="submit" class="btn btn-primary" mat-stroked-button color="primary">Register</button>
        <img *ngIf="loading" src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
      </div>
      <div *ngIf="error" class="alert alert-danger">{{error}}</div>
    </fieldset>
  </form>
</div>
