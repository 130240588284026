import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService } from '../service/authentication.service';
import { first } from 'rxjs/operators';
//import { PasswordValidation } from '../_helpers/PasswordValidation';

@Component({
  selector: 'app-whitelistserialnumber',
  templateUrl: './whitelistserialnumber.component.html',
  styleUrls: ['./whitelistserialnumber.component.css']
})
export class WhitelistSerialnumberComponent implements OnInit, AfterViewInit {

  @ViewChild('search') searchElement: ElementRef;
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService
    ) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      serialnumber: ['', [Validators.required]],
    }    
    
    );

  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    //this.loading = true;
    this.authenticationService.whitelistserialnumber(this.f.serialnumber.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data != null && data.status == 200) {

            this.error = data.response;
            this.error = '';
            this.f.serialnumber.setValue('');
            setTimeout(() => { // this will make the execution after the above boolean has changed
              this.searchElement.nativeElement.focus();
            }, 0);
            //"Request sent for Serialnumber Whitelisting successfully. Access will be granted after administrator approves it.";
            //this.loading = false;
            //if (data.response == 'Device Whitelisted') 
            //{
              alert(data.response);
              // this.router.navigate(
              //   ['/warehousesrapdetail'],
              //   { queryParams: { serialNumber: this.f.serialnumber.value } }
              // );
            //}
          }
          else {
            this.error = data.response;
            //this.loading = false;
            alert(data.response);

          }
        },
        error => {
          this.error = error;
          //this.loading = false;
        });
  }

  ngAfterViewInit() {
    this.searchElement.nativeElement.focus();
  }


}
