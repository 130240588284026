<div style="padding-left:5px !important;padding-right:5px;">
  <ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="square-jelly-box" [fullScreen]="true">
    <p style="color: white"> Loading... </p>
  </ngx-spinner>
  <div>
    <br />
    <h1>Filter Manufacturing Test Dashboard</h1>
  </div>
  <div class="mat-form-field--inline">
    <h1>Results Filters</h1>

    <!-- <mat-form-field>
      <input matInput class="form-field" [formControl]="test_id_minFilter" #test_id_min name="test_id_min" placeholder="Test ID Min">
    </mat-form-field> -->
    <!-- <mat-form-field>
      <input matInput class="form-field" [formControl]="test_id_maxFilter" #test_id_max name="test_id_max" placeholder="test id max">
    </mat-form-field> -->
    <mat-form-field>
      <input matInput class="form-field" [formControl]="test_id_countFilter" #test_id_count name="test_id_count" placeholder="Count">
    </mat-form-field>

    <mat-form-field>
      <input matInput class="form-field" [formControl]="test_tatus_passcountFilter" #test_tatus_passcount name="test_tatus_passcount" placeholder="Pass">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="test_tatus_failcountFilter" #test_tatus_failcount name="test_tatus_failcount" placeholder="Fail">
    </mat-form-field>
     <mat-form-field>
      <input matInput class="form-field" [formControl]="test_tatus_othercountFilter" #test_tatus_othercount name="test_tatus_othercount" placeholder="Other">
    </mat-form-field>

    <mat-form-field>
      <input matInput class="form-field" [formControl]="overall_status_0Filter" #overall_status_0 name="overall_status_0" placeholder="Overall 0">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="overall_status_1Filter" #overall_status_1 name="overall_status_1" placeholder="Overall 1">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="overall_status_2Filter" #overall_status_2 name="overall_status_2" placeholder="Overall 2">
    </mat-form-field>
    <mat-form-field>
      <input matInput class="form-field" [formControl]="overall_status_othercountFilter" #overall_status_othercount name="overall_status_othercount" placeholder="Overall Other">
    </mat-form-field>
    
   <mat-form-field>
    <input matInput class="form-field" [formControl]="overall_current_minFilter" #overall_current_min name="overall_current_min" placeholder="Overall Min">
   </mat-form-field>
   <mat-form-field>
     <input matInput class="form-field" [formControl]="overall_current_maxFilter" #overall_current_max name="overall_current_max" placeholder="Overall Max">
   </mat-form-field>
   <mat-form-field>
    <input matInput class="form-field" [formControl]="overall_current_avgFilter" #overall_current_avg name="overall_current_avg" placeholder="Overall Avg">
   </mat-form-field>

   <mat-form-field>
    <input matInput class="form-field" [formControl]="mfg_timestamp_minFilter" #mfg_timestamp_min name="mfg_timestamp_min" placeholder="MFG Date Min">
  </mat-form-field>
  <mat-form-field>
     <input matInput class="form-field" [formControl]="mfg_timestamp_maxFilter" #mfg_timestamp_max name="mfg_timestamp_max" placeholder="MFG Date Max">
  </mat-form-field>

   <mat-form-field>
    <input matInput class="form-field" [formControl]="createdtime_utc_minFilter" #createdtime_utc_min name="createdtime_utc_min" placeholder="Created Min">
   </mat-form-field>
   <mat-form-field>
    <input matInput class="form-field" [formControl]="createdtime_utc_maxFilter" #createdtime_utc_max name="createdtime_utc_max" placeholder="Created Max">
   </mat-form-field>

   <mat-form-field>
    <input matInput class="form-field" [formControl]="created_byFilter" #created_by name="created_by" placeholder="UserID">
   </mat-form-field>
   <mat-form-field>
    <input matInput class="form-field" [formControl]="firstnameFilter" #firstname name="firstname" placeholder="First">
   </mat-form-field>
   <mat-form-field>
    <input matInput class="form-field" [formControl]="lastnameFilter" #lastname name="lastname" placeholder="Last">
   </mat-form-field>

    <p style="text-align:end">
      <button type="button" mat-stroked-button color="primary" (click)="exportAsExcel()">Export as Excel</button>
    </p>
  </div>

  <div #TABLE>
    <table mat-table [dataSource]="MyDataSourceRows" matSort matSortStart="desc" class="mat-elevation-z8" style="width:100%;">
      
      <ng-container matColumnDef="test_id_min">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="test_id_min"> Test ID Min</th>
        <td mat-cell *matCellDef="let row"> {{row.test_id_min}} </td>
      </ng-container>
      <ng-container matColumnDef="test_id_max">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="test_id_max"> ID Min - Max</th>
        <td mat-cell *matCellDef="let row"> {{row.test_id_min}} - {{row.test_id_max}} </td>
      </ng-container>

      <ng-container matColumnDef="test_id_count">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="test_id_count"> Count </th>
        <td mat-cell *matCellDef="let row"> {{row.test_id_count}} </td>
      </ng-container>

      <ng-container matColumnDef="test_tatus_passcount">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="test_tatus_passcount"> PassCount </th>
        <td mat-cell *matCellDef="let row"> {{row.test_tatus_passcount}} </td>
      </ng-container>                                                                                                                                                                               
      <ng-container matColumnDef="test_tatus_failcount">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="test_tatus_failcount"> Pass - Fail - Other</th>
        <td mat-cell *matCellDef="let row"> {{row.test_tatus_passcount}} - {{row.test_tatus_failcount}} - {{row.test_tatus_othercount}}</td>
      </ng-container>
      <ng-container matColumnDef="test_tatus_othercount">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="test_tatus_othercount"> test_tatus_othercount </th>
        <td mat-cell *matCellDef="let row"> {{row.test_tatus_othercount}} </td>
      </ng-container>

      <ng-container matColumnDef="overall_status_0">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="overall_status_0"> overall_status_0 </th>
        <td mat-cell *matCellDef="let row"> {{row.overall_status_0}} </td>
      </ng-container>
      <ng-container matColumnDef="overall_status_1">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="overall_status_1"> overall_status_1 </th>
        <td mat-cell *matCellDef="let row"> {{row.overall_status_1}} </td>
      </ng-container>
      <ng-container matColumnDef="overall_status_2">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="overall_status_2"> overall_status_2 </th>
        <td mat-cell *matCellDef="let row"> {{row.overall_status_2}} </td>
      </ng-container>

      <ng-container matColumnDef="overall_status_othercount">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="overall_status_othercount"> Overall 0 - 1 - 2 - Other  </th>
        <td mat-cell *matCellDef="let row"> {{row.overall_status_0}} - {{row.overall_status_1}} - {{row.overall_status_2}} - {{row.overall_status_othercount}} </td>
      </ng-container>
      <ng-container matColumnDef="mfg_timestamp_min">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="mfg_timestamp_min"> mfg_timestamp_min </th>
        <td mat-cell *matCellDef="let row"> {{row.mfg_timestamp_min}} </td>
      </ng-container>
      <ng-container matColumnDef="mfg_timestamp_max">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="mfg_timestamp_max"> MFG Min - Max </th>
        <td mat-cell *matCellDef="let row"> {{row.mfg_timestamp_min}} - {{row.mfg_timestamp_max}} </td>
      </ng-container>

      <ng-container matColumnDef="overall_current_min">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="overall_current_min"> overall_current_min </th>
        <td mat-cell *matCellDef="let row"> {{row.overall_current_min}} </td>
      </ng-container>
      <ng-container matColumnDef="overall_current_max">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="overall_current_max"> Overall Min - Max - Avg</th>
        <td mat-cell *matCellDef="let row"> {{row.overall_current_min}} - {{row.overall_current_max}} - {{row.overall_current_avg}}</td>
      </ng-container>
      <ng-container matColumnDef="overall_current_avg">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="overall_current_avg"> overall_current_avg </th>
        <td mat-cell *matCellDef="let row"> {{row.overall_current_avg}} </td>
      </ng-container>

      <ng-container matColumnDef="created_by">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="created_by"> Last, First (UserID) </th>
        <td mat-cell *matCellDef="let row"> {{row.lastname}}, {{row.firstname}} ({{row.created_by}}) </td>
      </ng-container>
      <ng-container matColumnDef="lastname">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="lastname"> lastname </th>
        <td mat-cell *matCellDef="let row"> {{row.lastname}}  </td>
      </ng-container>

      <ng-container matColumnDef="createdtime_utc_min">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="createdtime_utc_min"> createdtime_utc_min </th>
        <td mat-cell *matCellDef="let row"> {{row.createdtime_utc_min}} </td>
      </ng-container>
      <ng-container matColumnDef="createdtime_utc_max">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="createdtime_utc_max"> Created Min - Max </th>
        <td mat-cell *matCellDef="let row"> {{row.createdtime_utc_min}} - {{row.createdtime_utc_max}} </td>
      </ng-container>

      <ng-container matColumnDef="firstname">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef mat-sort-header="firstname"> firstname </th>
        <td mat-cell *matCellDef="let row"> {{row.firstname}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>                                                                                                                                                                                         
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>

    <table mat-table [dataSource]="MyDataSourceRowsAggregate" matSort matSortStart="desc" class="mat-elevation-z8" style="width:100%;">
      
      <ng-container matColumnDef="test_id_min">
        <th style="display: none; bottom: 0px; position: sticky; z-index: 10;" class="w-75 th-left" mat-header-cell *matHeaderCellDef> Test ID Min</th>
        <td mat-cell *matCellDef="let row"> {{row.test_id_min}} </td>
      </ng-container>
      <ng-container matColumnDef="test_id_max">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef>ID Min - Max</th>
        <td mat-cell *matCellDef="let row"> {{row.test_id_min}} - {{row.test_id_max}} </td>
      </ng-container>

      <ng-container matColumnDef="test_id_count">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef> Count </th>
        <td mat-cell *matCellDef="let row"> {{row.test_id_count}} </td>
      </ng-container>

      <ng-container matColumnDef="test_tatus_passcount">
        <th style="display: none; bottom: 0px; position: sticky; z-index: 10;" class="w-75 th-left" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"> {{row.test_tatus_passcount}} </td>
      </ng-container>                                                                                                                                                                               <ng-container matColumnDef="test_tatus_failcount">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef >Pass - Fail - Other </th>
        <td mat-cell *matCellDef="let row"> {{row.test_tatus_passcount}} - {{row.test_tatus_failcount}} - {{row.test_tatus_othercount}}</td>
      </ng-container>
      <ng-container matColumnDef="test_tatus_othercount">
        <th style="display: none; bottom: 0px; position: sticky; z-index: 10;" class="w-75 th-left" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"> {{row.test_tatus_othercount}} </td>
      </ng-container>

      <ng-container matColumnDef="overall_status_0">
        <th style="display: none; bottom: 0px; position: sticky; z-index: 10;" class="w-75 th-left" mat-header-cell *matHeaderCellDef ></th>
        <td mat-cell *matCellDef="let row"> {{row.overall_status_0}} </td>
      </ng-container>
      <ng-container matColumnDef="overall_status_1">
        <th style="display: none; bottom: 0px; position: sticky; z-index: 10;" class="w-75 th-left" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"> {{row.overall_status_1}} </td>
      </ng-container>
      <ng-container matColumnDef="overall_status_2">
        <th style="display: none; bottom: 0px; position: sticky; z-index: 10;" class="w-75 th-left" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"> {{row.overall_status_2}} </td>
      </ng-container>

      <ng-container matColumnDef="overall_status_othercount">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef>Overall 0 - 1 - 2 - Other</th>
        <td mat-cell *matCellDef="let row"> {{row.overall_status_0}} - {{row.overall_status_1}} - {{row.overall_status_2}} - {{row.overall_status_othercount}} </td>
      </ng-container>
      <ng-container matColumnDef="mfg_timestamp_min">
        <th style="display: none; bottom: 0px; position: sticky; z-index: 10;" class="w-75 th-left" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"> {{row.mfg_timestamp_min}} </td>
      </ng-container>
      <ng-container matColumnDef="mfg_timestamp_max">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef>MFG Min - Max</th>
        <td mat-cell *matCellDef="let row"> {{row.mfg_timestamp_min}} - {{row.mfg_timestamp_max}} </td>
      </ng-container>

      <ng-container matColumnDef="overall_current_min">
        <th style="display: none; bottom: 0px; position: sticky; z-index: 10;" class="w-75 th-left" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"> {{row.overall_current_min}} </td>
      </ng-container>
      <ng-container matColumnDef="overall_current_max">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef>Overall Min - Max - Avg</th>
        <td mat-cell *matCellDef="let row"> {{row.overall_current_min}} - {{row.overall_current_max}} - {{row.overall_current_avg}}</td>
      </ng-container>
      <ng-container matColumnDef="overall_current_avg">
        <th style="display: none; bottom: 0px; position: sticky; z-index: 10;" class="w-75 th-left" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"> {{row.overall_current_avg}} </td>
      </ng-container>

      <ng-container matColumnDef="created_by">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"> {{row.lastname}}         .          {{row.firstname}}          .           {{row.created_by}} </td>
      </ng-container>
      <ng-container matColumnDef="lastname">
        <th style="display: none; bottom: 0px; position: sticky; z-index: 10;" class="w-75 th-left" mat-header-cell *matHeaderCellDef> </th>
        <td mat-cell *matCellDef="let row"> {{row.lastname}}  </td>
      </ng-container>

      <ng-container matColumnDef="createdtime_utc_min">
        <th style="display: none; bottom: 0px; position: sticky; z-index: 10;" class="w-75 th-left" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"> {{row.createdtime_utc_min}} </td>
      </ng-container>
      <ng-container matColumnDef="createdtime_utc_max">
        <th class="w-75 th-left" mat-header-cell *matHeaderCellDef> Created Min - Max </th>
        <td mat-cell *matCellDef="let row"> {{row.createdtime_utc_min}} - {{row.createdtime_utc_max}} </td>
      </ng-container>

      <ng-container matColumnDef="firstname">
        <th style="display: none; bottom: 0px; position: sticky; z-index: 10;" class="w-75 th-left" mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let row"> {{row.firstname}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;">
      </tr>
    </table>

  </div>

  <mat-paginator class="paginator--centered" [pageSizeOptions]="[10, 30, 50,100]" showFirstLastButtons></mat-paginator>
</div>
