import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { SerialnumberComponent } from './serialnumber/serialnumber.component';
import { SerialnumberlistComponent } from './serialnumberlist/serialnumberlist.component';
import { WarehousesraplistComponent } from './warehousesraplist/warehousesraplist.component';
import { WarehousesrapdetailComponent } from './warehousesrapdetail/warehousesrapdetail.component';
import { FirmwarehistoryComponent } from './firmwarehistory/firmwarehistory.component';
import { FirmwarefileuploadComponent } from './firmwarefileupload/firmwarefileupload.component';
import { FirmwarefileslistComponent } from './firmwarefileslist/firmwarefileslist.component';
import { FilterFirmwarefileslistComponent } from './filterfirmwarefileslist/filterfirmwarefileslist.component';
import { FirmwareloaderfilesuploadComponent } from './firmwareloaderfilesupload/firmwareloaderfilesupload.component';
import { FirmwareLoaderFilesComponent } from './firmwareloaderfiles/firmwareloaderfiles.component';
import { AuthGuard } from './_guards/auth.guard';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { WhitelistComponent } from './whitelist/whitelist.component';
import { WhitelistSerialnumberComponent } from './whitelistserialnumber/whitelistserialnumber.component';
import { UserslistComponent } from './userslist/userslist.component';
import { FilterDashboardComponent } from './filterdashboard/filterdashboard.component';
import { JobUserslistComponent } from './jobuserslist/jobuserslist.component';
import { SectigofilesComponent } from './sectigofiles/sectigofiles.component';
import { RapdevicelogfileuploadComponent } from './rapdevicelogfileupload/rapdevicelogfileupload.component';
import { RapdevicelogfilesComponent } from './rapdevicelogfiles/rapdevicelogfiles.component';
// import { ApproveuserComponent } from './modals/approveuser/approveuser.component'

const routes: Routes = [
  { path: 'serialnumber', redirectTo: 'serialnumber', canActivate: [AuthGuard] },
  { path: 'serialnumber', component: SerialnumberComponent, canActivate: [AuthGuard] },
  { path: 'serialnumberlist', component: SerialnumberlistComponent, canActivate: [AuthGuard] },
  { path: 'warehousesraplist', component: WarehousesraplistComponent, canActivate: [AuthGuard] },
  { path: 'warehousesrapdetail', component: WarehousesrapdetailComponent, canActivate: [AuthGuard] },
  { path: 'firmwarehistory', component: FirmwarehistoryComponent, canActivate: [AuthGuard] },
  { path: '', component: SerialnumberlistComponent, canActivate: [AuthGuard] },
  { path: 'firmwarefileupload', component: FirmwarefileuploadComponent, canActivate: [AuthGuard] },
  { path: 'rapdevicelogfileupload', component: RapdevicelogfileuploadComponent, canActivate: [AuthGuard] },
  { path: 'rapdevicelogfiles', component: RapdevicelogfilesComponent, canActivate: [AuthGuard] },
  { path: 'firmwarefileslist', component: FirmwarefileslistComponent, canActivate: [AuthGuard] },
  { path: 'filterfirmwarefileslist', component: FilterFirmwarefileslistComponent, canActivate: [AuthGuard] },
  { path: 'firmwareloaderfilesupload', component: FirmwareloaderfilesuploadComponent, canActivate: [AuthGuard] },
  { path: 'firmwareloaderfiles', component: FirmwareLoaderFilesComponent, canActivate: [AuthGuard] },
  { path: 'sectigofiles', component: SectigofilesComponent, canActivate: [AuthGuard] },
  { path: 'login', component: LoginComponent },
  { path: 'register', component: RegisterComponent },
  { path: 'whitelist', component: WhitelistComponent},
  { path: 'whitelistserialnumber', component: WhitelistSerialnumberComponent, canActivate: [AuthGuard] },
  { path: 'userslist', component: UserslistComponent, canActivate: [AuthGuard] },
  { path: 'jobuserslist', component: JobUserslistComponent, canActivate: [AuthGuard] },
  { path: 'filterdashboard', component: FilterDashboardComponent , canActivate: [AuthGuard]},
  // { path: 'approveuser', component: ApproveuserComponent , canActivate: [AuthGuard]},
  { path: '**', redirectTo: '' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
