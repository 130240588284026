import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';

import { AuthenticationService } from '../service/authentication.service';

@Component({ templateUrl: 'login.component.html' })
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  loading = false;
  submitted = false;
  returnUrl: string;
  error = '';

  isAdmin: boolean=false;
  isContributor: boolean = false;
  isReader: boolean = false;

  isFilterAdmin: boolean=false;
  isFilterContributor: boolean = false;
  isFilterReader: boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private authenticationService: AuthenticationService) { }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],
      password: ['', Validators.required]
    });

    // reset login status
    //this.authenticationService.logout();

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
  }

  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }

    this.loading = true;
    this.authenticationService.login(this.f.username.value.toLowerCase(), this.f.password.value)
      .pipe(first())
      .subscribe(
        data => {
          if (data != null && data.isSuccess) {
            this.assignRole();
            this.navigateOnRole();
            //this.router.navigate([this.returnUrl]);
          }
          else {
            this.error = data.message;
            this.loading = false;
          }
        },
        error => {
          this.error = error;
          this.loading = false;
        });
  }

  navigateOnRole()
  {
    if(this.isAdmin)
    {
      //this.router.navigate([this.returnUrl]);
      this.router.navigate(['/firmwarehistory']);
    }

    if(this.isContributor)
    {
      //this.router.navigate([this.returnUrl]);
      this.router.navigate(['/serialnumberlist']);
    }

    if(this.isReader)
    {
      //this.router.navigate([this.returnUrl]); 
      this.router.navigate(['/serialnumberlist']);
    }

    if(this.isFilterAdmin)
    {
      this.router.navigate(['/filterdashboard']);
    }

    if(this.isFilterContributor)
    {
      this.router.navigate(['/filterfirmwarefileslist']);
    }

    if(this.isFilterReader)
    {
      this.router.navigate(['/filterfirmwarefileslist']);
    }

  }
  assignRole()
  {
    this.isAdmin = localStorage.getItem('role') == 'admin' ? true : false;
    this.isContributor = localStorage.getItem('role') == 'contributor' ? true : false;
    this.isReader = localStorage.getItem('role') == 'reader' ? true : false;

    this.isFilterAdmin = localStorage.getItem('role') == 'filteradmin' ? true : false;
    this.isFilterContributor = localStorage.getItem('role') == 'filtercontributor' ? true : false;
    this.isFilterReader = localStorage.getItem('role') == 'filterreader' ? true : false;

  }
}
