export class SearchObject
{
    searchValues : SearchValues[]
    ordering: string
    offset: BigInteger
    limit: BigInteger
}

export class SearchValues
{
    searchKey: string
    searchValue: string
}